<template>
  <div class="user-game-data__container">
    <div class="user-game-data__first-row">
      <button
        @click="shareLineMessage"
        class="user-game-data__share-to-friends"
        :style="{
          display: !isGameDraft && gameDetails.enableShare ? 'flex' : 'none',
        }"
      >
        <img :src="shareIcon" />
        {{ $t('FRIEND') }}
      </button>
      <button
        @click="handlePlayAgain(false)"
        class="user-game-data__play-again"
        :style="{ display: displayScratchPlayAgain ? 'flex' : 'none' }"
      >
        {{ $t('PLAY_AGAIN') }}
      </button>
    </div>
  </div>
</template>

<script>
import shareIcon from '../images/icon-share.png'

export default {
  name: 'UserGameData',
  props: {
    gameDetails: Object,
    handlePlayAgain: Function,
    displayScratchPlayAgain: Boolean,
    isGameDraft: Boolean,
    shareLineMessage: Function,
  },
  data() {
    return {
      shareIcon,
    }
  },

  mounted() {},

  methods: {},
}
</script>

<style>
.user-game-data__container {
  margin: 24px 30px 16px;
}
.user-game-data__play-again {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75);
  font-weight: 600;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 auto;
  width: 150px;
  font-size: 14px;
  color: black
}
.user-game-data__share-to-friends {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75);
  font-weight: 600;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 auto;
  width: 150px;
  font-size: 14px;
  color:black
}

.user-game-data__share-to-friends img {
  width: 15px;
  margin-right: 5px;
}
.user-game-data__first-row {
  display: flex;
  justify-content: space-between;
}
</style>