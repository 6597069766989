var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"machine-wrapper"},[_c('div',{staticClass:"slot-machine-wrapper"},[_c('div',{staticClass:"slot-machine__reel-container"},[_c('div',{ref:"firstReelRef",staticClass:"slot-machine__reel slot_machine_reel1"}),_c('div',{ref:"secondReelRef",staticClass:"slot-machine__reel slot_machine_reel2"}),_c('div',{ref:"thirdReelRef",staticClass:"slot-machine__reel slot_machine_reel3"}),_c('div',{staticClass:"slot-machine__reel-overlay"})]),_c('div',{staticClass:"slot-machine__top-background",style:({
        background: _vm.gameDetails.secondaryColor,
      })}),_c('div',{staticClass:"slot-machine__main-background",style:({
        borderColor: ("transparent transparent " + (_vm.gameDetails.mainColor)),
      })}),_c('div',{staticClass:"slot-machine__bottom-background",style:({
        background: _vm.gameDetails.secondaryColor,
      })}),_c('img',{staticClass:"slot-machine__background-img",attrs:{"src":_vm.slotMachineImage,"alt":""}}),_c('div',{staticClass:"slot-machine__pole-background",style:({
        background: _vm.gameDetails.secondaryColor,
      })}),_c('div',{staticClass:"slot-machine__pole-background-click-area",on:{"click":_vm.handleStartGame}}),(_vm.gameDetails.startButtonImage)?_c('img',{staticClass:"slot-machine__start-btn",attrs:{"src":_vm.gameDetails.startButtonImage,"alt":""},on:{"click":_vm.handleStartGame}}):_c('img',{staticClass:"slot-machine__start-btn",attrs:{"src":_vm.startButtonImage,"alt":""},on:{"click":_vm.handleStartGame}})])])}
var staticRenderFns = []

export { render, staticRenderFns }