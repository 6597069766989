<template>
  <section>
    <div class="game-rule__container">
      <div class="user-game-data__content">
        <div class="user-game-data__play-count">
          {{ $t('REMAINING_TIMES') }}： <span>{{ remainingDraws }}</span>
        </div>
        <button
          @click="$emit('handleRecordsModal', true)"
          class="user-game-data__prize-record"
        >
          {{ $t('RECORD') }}
        </button>
      </div>
      <div
        class="game-rule__content"
        :style="{
          color: gameDetails.ruleTextColor ? gameDetails.ruleTextColor : '#000',
        }"
      >
        <div v-if="gameDetails.rule">
          <p v-for="(item, index) in ruleArr" :key="index">{{ item }}</p>
        </div>
        <div v-else>
          <p>{{ $t('GAME_RULES') }}</p>
        </div>
      </div>
    </div>
    <div
      class="game-prize__container"
      :style="{ display: gameDetails.enablePrizeImage ? 'block' : 'none' }"
    >
      <img :src="gameDetails.prizeImage" class="game-prize__image" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'GameRuleAndPrize',
  props: {
    gameDetails: Object,
    remainingDraws: Number,
  },
  computed: {
    ruleArr() {
      return this.gameDetails.rule.split('\n')
    },
  },
}
</script>

<style>
.game-rule__container,
.game-prize__container {
  margin: 0 24px 24px;
}
.game-rule__container {
  background: rgba(255, 255, 255, 0.5);
  padding: 12px 12px 16px;
  font-size: 14px;
  border-radius: 15px;
}
.user-game-data__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
.user-game-data__play-count {
  margin-bottom: 12px;
  color: #5f5f5f;
  font-size: 14px;
}
.user-game-data__play-count span {
  color: #000;
  font-size: 20px;
}
.user-game-data__prize-record {
  border: 1px solid #000;
  border-radius: 15px;
  padding: 5px 20px;
  font-weight: 600;
  background: none;
  font-size: 14px;
  color: black;
}
.game-rule__content p {
  font-family: ‘cwTeXYen’, sans-serif;
  margin-bottom: 5px;
  word-break: break-all;
}
.game-prize__image {
  width: 100%;
}
</style>