<template>
  <div class="chouqian__wrapper">
    <img
      :src="chouqianSticks"
      :class="[
        isAnimationOn ? 'chouqian__sticks animation' : 'chouqian__sticks',
      ]"
    />
    <div class="chouqian__vessel-wrapper">
      <img :src="chouqianRim" class="chouqian__rim" />
      <div
        class="chouqian__vessel-color-square"
        :style="{
          'background-color': gameDetails.mainColor
            ? gameDetails.mainColor
            : '#F4B789',
        }"
      />
      <div
        class="chouqian__vessel-color-trapezoid"
        :style="{
          'border-top': gameDetails.mainColor
            ? `33px solid ${gameDetails.mainColor}`
            : '33px solid #F4B789',
        }"
      />
      <img :src="chouqianContainer" class="chouqian__vessel-image" />
    </div>
    <div @click="$emit('handleStartGame')" class="chouqian__start-game-button">
      <img :src="startButtonImage" />
    </div>
  </div>
</template>

<script>
import chouqianContainer from '../images/chouqian-container.png'
import chouqianSticks from '../images/chouqian-sticks.png'
import chouqianRim from '../images/chouqian-rim.png'

export default {
  name: 'ChouqianHooks',
  props: {
    isAnimationOn: Boolean,
    gameDetails: Object,
    startButtonImage: String
  },
  data() {
    return {
      chouqianContainer,
      chouqianSticks,
      chouqianRim,
    }
  },
}
</script>

<style>
.main-background-image {
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100%;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}
.main-background-image img {
  width: 100%;
}

.games__main-container {
  position: relative;
  padding-bottom: 60px;
}
.chouqian__wrapper {
  position: relative;
  width: 250px;
  height: 580px;
  margin: 0 auto;
  padding-top: 160px;
}
.chouqian__vessel-wrapper {
  position: relative;
  z-index: unset;
  margin-top: 28%;
}
.chouqian__vessel-image {
  position: absolute;
  width: 100%;
}
.chouqian__vessel-color-square {
  background-color: #8e2828;
  position: absolute;
  width: 97%;
  height: 270px;
  margin-top: 10%;
  transform: translateX(50%);
  right: 49.5%;
}
.chouqian__vessel-color-trapezoid {
  border-left: 63px solid transparent;
  border-right: 63px solid transparent;
  border-top: 33px solid #8e2828;
  width: 98%;
  position: absolute;
  margin-top: 116%;
  transform: translateX(50%);
  right: 50%;
}
.chouqian__rim {
  position: absolute;
  top: 134%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  z-index: 4;
  width: 100%;
}
.chouqian__sticks {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  z-index: 4;
  position: absolute;
  left: 0;
  right: 0;
}
.chouqian__sticks.animation {
  animation: shake-lr 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10%,
  90% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
}
.chouqian__start-game-button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
  height: 120px;
  cursor: pointer;
  z-index: 4;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.chouqian__start-game-button img {
  width: 100%;
  max-height: 120px;
}
</style>