<template>
  <div class="machine-wrapper">
    <div class="slot-machine-wrapper">
      <div class="slot-machine__reel-container">
        <div ref="firstReelRef" class="slot-machine__reel slot_machine_reel1" />
        <div
          ref="secondReelRef"
          class="slot-machine__reel slot_machine_reel2"
        />
        <div ref="thirdReelRef" class="slot-machine__reel slot_machine_reel3" />
        <div class="slot-machine__reel-overlay" />
      </div>

      <div
        class="slot-machine__top-background"
        :style="{
          background: gameDetails.secondaryColor,
        }"
      />
      <div
        class="slot-machine__main-background"
        :style="{
          borderColor: `transparent transparent ${gameDetails.mainColor}`,
        }"
      />
      <div
        class="slot-machine__bottom-background"
        :style="{
          background: gameDetails.secondaryColor,
        }"
      />
      <img
        class="slot-machine__background-img"
        :src="slotMachineImage"
        alt=""
      />
      <div
        class="slot-machine__pole-background"
        :style="{
          background: gameDetails.secondaryColor,
        }"
      />
      <div
        @click="handleStartGame"
        class="slot-machine__pole-background-click-area"
      />
      <img
        v-if="gameDetails.startButtonImage"
        @click="handleStartGame"
        class="slot-machine__start-btn"
        :src="gameDetails.startButtonImage"
        alt=""
      />
      <img
        v-else
        @click="handleStartGame"
        class="slot-machine__start-btn"
        :src="startButtonImage"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import mergeImages from 'merge-images'
import anime from 'animejs/lib/anime.es.js'

import slotMachineImage from '../images/slot-machine/slot-machine.png'
import startButtonImage from '../images/slot-machine/start-button-image.png'

export default {
  name: 'SlotMachine',
  props: {
    gameDetails: Object,
    postStartGame: Function,
  },
  computed: {
    validPrizes() {
      return this.gameDetails.prizes.filter(prize => !prize.noPrize)
    },
  },
  data() {
    return {
      startButtonImage,
      slotMachineImage,
      isGameProcessing: false,
      machineConfig: {},
      reelsRefsArray: [],
      time: null,
    }
  },
  mounted() {
    this.getRef()
    this.setMachineConfig()
    this.renderReelImages()
  },

  methods: {
    getRef() {
      this.reelsRefsArray = [
        null,
        this.$refs.firstReelRef,
        this.$refs.secondReelRef,
        this.$refs.thirdReelRef,
      ]
    },
    setMachineConfig() {
      this.machineConfig.current = {
        stripHeight: (this.validPrizes.length * 120 * 54) / 120, // Update this to match the strip PNG   stripe image height 公式: 原圖長(120 * 獎項數) * 54 / 原圖寬(120)
        alignmentOffset: -20, // Play around with this until reels are properly aligned post-spin

        secondReelStopTime: 1000, // since first reel's stop time, not since animation beginning
        thirdReelStopTime: 1000, // since second reel's stop time, not since animation beginning

        reelSpeedDifference: 0, // speed difference between the 3 reels
        reelSpeed1Delta: 50, // "Fast speed"
        reelSpeed1Time: 0, // How long does fast speed lasts.
        reelSpeed2Delta: 50, // Slow speed

        positioningTime: 200,
        bounceHeight: 200,
        bounceTime: 1000,

        iconsPerReel: this.validPrizes.length,
      }
    },

    renderReelImages() {
      // set image reel
      // 這邊要 filter 掉沒中獎的 prize
      let imageArray = this.validPrizes.map((prize, index) => {
        return {
          src: prize.image + '?canvas=true', // prevent browser cache the img tag response
          x: 0,
          y: 120 * index,
        }
      })
      mergeImages(imageArray, {
        width: 120,
        height: 120 * this.validPrizes.length,
        crossOrigin: 'Anonymous',
      })
        .then(b64 => {
          this.reelsRefsArray.forEach(node => {
            if (!node) return
            node.style.background = `transparent url(${b64}) 0 0 repeat-y`
            node.style.backgroundSize = 'contain'
          })
          // document.getElementById('merged-img').src = b64
          // vm.couldShowLoading = false;
          // vm.couldShowGamePage = true;
        })
        .catch(error => {
          console.log('load image fail')
          console.log(error)
        })
    },

    spinReel(index, timeOffset) {
      let startTime = Date.now()
      let elReel = this.reelsRefsArray[index]
      // Change the initial position so that, if a screenshot is taken mid-spin, reels are mis-aligned
      elReel.style.top =
        -(Math.random() * this.machineConfig.current.stripHeight * 2) + 'px'

      let curPos = parseInt(elReel.style.top.replace('px', ''), 10)

      // 改用箭頭函數 this 才會指到 vue 的 instance
      this.time = setInterval(() => {
        elReel.style.top = curPos + 'px'
        // Choose between fast and slow movements
        if (
          Date.now() <
          startTime + this.machineConfig.current.reelSpeed1Time + timeOffset
        ) {
          curPos += this.machineConfig.current.reelSpeed1Delta
        } else {
          curPos += this.machineConfig.current.reelSpeed2Delta
        }
        curPos += index * this.machineConfig.current.reelSpeedDifference
        if (curPos > 0) {
          curPos = -this.machineConfig.current.stripHeight * 2
        }
      }, 20)
      elReel.dataset.spinTimer = this.time
      // elReel.setAttribute('data', `spinTimer: ${timerID}`); // Store the inerval timer so we can kill it when stopping
    },

    startSpinningReels() {
      this.spinReel(1, 0)
      this.spinReel(2, this.machineConfig.current.secondReelStopTime)
      this.spinReel(
        3,
        this.machineConfig.current.secondReelStopTime +
          this.machineConfig.current.thirdReelStopTime
      )
    },

    stopReel(index, outcome) {
      let elReel = this.reelsRefsArray[index]
      var timerID = elReel.dataset.spinTimer
      window.clearInterval(timerID)
      elReel.setAttribute('data', `spinTimer: ${null}`)

      if (outcome != null) {
        // the whole strip repeats thrice, so we don't have to care about looping
        // alignmentOffset is kind of empirical...
        let distanceBetweenIcons =
          this.machineConfig.current.stripHeight /
          this.machineConfig.current.iconsPerReel
        let finalPosition =
          -this.machineConfig.current.stripHeight -
          (outcome - 1) * distanceBetweenIcons +
          this.machineConfig.current.alignmentOffset

        // Animation two: Elastic Easing
        elReel.style.top =
          finalPosition - this.machineConfig.current.stripHeight + 'px'
        anime({
          targets: elReel,
          top: finalPosition + this.machineConfig.current.bounceHeight + 'px',
          duration: this.machineConfig.current.positioningTime,
          easing: 'linear',
          complete: () => {
            anime({
              targets: elReel,
              top: finalPosition + 'px',
              duration: this.machineConfig.current.bounceTime,
              easing: 'easeOutElastic',
            })
          },
        })
      }
    },

    stopReelsAndEndSpin({ winPrize, isStartGameSuccessful }) {
      // Make the reels stop spinning one by one
      let spinResult = {}

      if (!isStartGameSuccessful) {
        // 遊戲開始失敗的狀態
        spinResult = {
          reels: this.generateRandomReels(),
        }
      } else {
        // 正常開始遊戲
        let winPrizeIndex = this.validPrizes.findIndex(
          prize => prize.id === winPrize.prizeId
        )
        if (!winPrize.noPrize) {
          // 如果有得獎則停在那個獎品
          /*
          winPrizeIndex 的範圍在 0 ~ validPrizesLength - 1 之間
         */
          spinResult = {
            reels: [winPrizeIndex, winPrizeIndex, winPrizeIndex],
          }
        } else {
          // 如果沒得獎則隨機停在某個順序
          // generate random reels from current prize length

          spinResult = {
            reels: this.generateRandomReels(),
          }
        }
      }

      var baseTimeout = 0
      window.setTimeout(() => {
        this.stopReel(1, spinResult.reels[0])
      }, baseTimeout)
      baseTimeout += this.machineConfig.current.secondReelStopTime
      window.setTimeout(() => {
        this.stopReel(2, spinResult.reels[1])
      }, baseTimeout)
      baseTimeout += this.machineConfig.current.thirdReelStopTime
      window.setTimeout(() => {
        this.stopReel(3, spinResult.reels[2])
      }, baseTimeout)
      baseTimeout += 1000
      window.setTimeout(() => {
        // 若成功開始遊戲則顯示 prize modal
        if (isStartGameSuccessful && !winPrize.noPrize) {
          this.$emit('handleModalStatus', true)
          //   WonPrizeModalRef.current.openModal();
        } else if (isStartGameSuccessful && winPrize.noPrize) {
          //   NoPrizeModalRef.current.openModal();
          // redeem noPrize
          this.$emit('handleModalStatus', false)
          // 未中獎後端處理
          // this.$emit('handleRedeemPrize', winPrize.prizeRecordId, true)
        }
        this.isGameProcessing = false
      }, baseTimeout)
    },

    generateRandomReels() {
      let reels = []

      for (let i = 0; i < 3; i++) {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          let number = Math.floor(Math.random() * this.validPrizes.length)
          if (reels.indexOf(number) === -1) {
            reels.push(number)
            break
          }
        }
      }
      return reels
    },

    async handleStartGame() {
      if (this.isGameProcessing) return
      this.isGameProcessing = true

      this.startSpinningReels()
      let winPrize = await this.postStartGame()

      if (!winPrize) {
        this.stopReelsAndEndSpin({
          winPrize: null,
          isStartGameSuccessful: false,
        })
      } else {
        setTimeout(() => {
          this.stopReelsAndEndSpin({
            winPrize: winPrize,
            isStartGameSuccessful: true,
          })
        }, 1000)
      }
    },
  },
}
</script>

<style>
/* ================
Slot Machine 拉霸
================ */
.machine-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slot-machine__main-container {
  position: relative;
  padding-bottom: 60px;
}

.slot-machine-wrapper {
  width: 300px;
  height: 400px;
  position: relative;
  margin: 200px auto 0;
  /* border: 1px solid; */
}

.slot-machine__reel-container {
  position: absolute;
  width: 190px;
  height: 123px;
  /* border: 1px solid red; */
  overflow: hidden;
  z-index: 5;

  top: 88px;
  right: 57px;
  border-radius: 3px;
}

.slot-machine__reel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: transparent url(../images/slot-machine/reel_overlay.png) 0 center
    no-repeat;
  background-size: cover;
}

.slot-machine__reel {
  position: absolute;
  width: 54px;
  height: 2160px;
  /* height: 1440px; stripeHeight * 3 */
  background: transparent url(../images/slot-machine/default-reel-strip.png) 0 0
    repeat-y;
  background-size: contain;
}

.slot_machine_reel1 {
  left: 3px;
  top: -1360px;
}

.slot_machine_reel2 {
  left: 67px;
  top: -992px;
}

.slot_machine_reel3 {
  left: 131px;
  top: -1116px;
}

.slot-machine__background-img {
  width: 300px;
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  top: 0;
  z-index: 4;
}

.slot-machine__start-btn {
  width: 180px;
  position: absolute;
  right: 60px;
  top: 295px;
  z-index: 5;
  /*box-shadow: 3px 3px 6px 0px black;*/
  border-radius: 6px;
}

.slot-machine__top-background {
  background: #ff6b6b;
  width: 268px;
  position: absolute;
  height: 77px;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
}

.slot-machine__bottom-background {
  background: #ff6b6b;
  position: absolute;
  width: 267px;
  height: 117px;
  top: 255px;
  z-index: 4;
  right: 50%;
  transform: translateX(50%);
  margin-right: 2px;
}

.slot-machine__main-background {
  width: 268px;
  height: 0;
  border-width: 0 16px 240px 16px;
  border-style: none solid solid;
  border-color: transparent transparent #408fff;
  position: absolute;
  z-index: 3;
  top: 34px;
  transform: translateX(50%);
  right: 50%;
  margin-right: 1px;
}

.slot-machine__pole-background {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ff6b6b;
  right: -15px;
  top: 119px;
}

.slot-machine__pole-background-click-area {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: transparent;
  right: -15px;
  top: 119px;
  z-index: 10;
}
</style>