import Vue from 'vue'
import VueRouter from 'vue-router'
import GetUserProfile from '../components/GetUserProfile'
import ErrorPage from '../components/ErrorPage'
import GamePage from '../components/GamePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: GetUserProfile,
    props: true,
  },
  {
    path: '/game-page',
    name: 'GamePage',
    component: GamePage,
    props: true
  },
  {
    path: '/error-page',
    name: 'ErrorPage',
    component: ErrorPage,
  },
]
const base = location.href.includes('lottery-game') ? '/lottery-game/' : '';

const router = new VueRouter({
  mode: 'history',
  base,
  routes
})

export default router
