<template>
  <div>
    <SweetModal
      ref="modal"
      :hide-close-button="true"
      overlay-theme="dark"
      @close="$emit('handleRecordsModal', false)"
    >
      <div class="prize-record-modal__wrapper">
        <div
          class="loading-indicator"
          :style="{ display: isLoadingPrizeRecord ? 'block' : 'none' }"
          v-if="isLoadingPrizeRecord"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <header v-else class="modal-header__wrapper prize-tabel">
          <img
            @click="$emit('handleRecordsModal', false)"
            :src="closeIcon"
            class="modal-header__close-button"
          />
          <div class="modal-header__title">{{ $t('RECORD') }}</div>
          <div
            class="prize-modal__draft-warning"
            :style="{ display: isGameDraft ? 'block' : 'none' }"
          >
            {{ $t('IN_BETA') }}
            <br />
            {{ $t('PRIZE_CAN_NOT_REDEEMED') }}
          </div>
          <div class="modal-header__table-header">
            <div>{{ $t('PRIZE_NAME') }}</div>
            <div>{{ $t('DATE') }}</div>
            <div>{{ $t('STATUS') }}</div>
          </div>
        </header>
        <table :style="{ display: isLoadingPrizeRecord ? 'none' : 'table' }">
          <tbody>
            <tr
              class="prize-record-modal__table-row"
              v-for="(item, index) in gamePrizeRecords"
              :key="index"
            >
              <td>
                {{ item.prizeName }}
              </td>
              <td>
                {{ item.drawAt | moment('YYYY/MM/DD HH:mm') }}
                <br />
              </td>
              <td v-if="item.noPrize"></td>
              <td
                v-else-if="item.received"
                class="prize-record-modal__already-redeemed"
              >
                <button>{{ $t('GOT') }}</button>
              </td>
              <td
                colspan="3"
                @click="handleRedeemPrize(item.recordId)"
                class="prize-record-modal__redeem-button"
                v-else
              >
                <button>{{ $t('GET') }}</button>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              :style="{
                display: gamePrizeRecords.length === 0 ? 'table-row' : 'none',
              }"
              class="prize-record-modal__empty-record"
            >
              <td colSpan="3">{{ $t('NO_WINNING_RECORD') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </SweetModal>
  </div>
</template>

<script>
import gameApi from '../restapi/game'
import { SweetModal } from 'sweet-modal-vue'

import closeIcon from '../images/icon-close.png'

export default {
  name: 'PrizeRecordsModal',
  props: {
    gameId: String,
    userId: String,
    isRecordsModal: Boolean,
    isGameDraft: Boolean,
  },
  data() {
    return {
      isLoadingPrizeRecord: false,
      gamePrizeRecords: [],
      closeIcon,
      isRedeem: false
    }
  },
  mounted() {
    this.handleGamePrizeRecords()
  },
  watch: {
    isRecordsModal(newVal) {
      if (newVal) {
        this.$refs.modal.open()
      } else {
        this.$refs.modal.close()
      }
      this.handleGamePrizeRecords()
    },
  },
  methods: {
    async handleGamePrizeRecords() {
      this.isLoadingPrizeRecord = true
      try {
        let res = await gameApi.getGamePrizeRecords(this.gameId, this.userId)
        let data = res.data.data
        this.gamePrizeRecords = data
        this.isLoadingPrizeRecord = false
      } catch (err) {
        console.log('getGamePrizeRecords: ' + err)
      }
    },
    async handleRedeemPrize(recordId) {
      if(this.isRedeem) return
      try {
        this.isRedeem = true
        await gameApi.RedeemPrize(this.gameId, this.userId, recordId)
        await this.handleGamePrizeRecords()
        this.$emit('handleSuccessModal')
      } catch (error) {
        console.log(error)
      } finally {
        this.isRedeem = false
      }
    },
  },
  components: {
    SweetModal,
  },
}
</script>

<style>
.modal-header__wrapper.prize-tabel {
  position: sticky;
  top: 0;
  background-color: #fff;
}
.modal-header__table-header {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}
.modal-header__table-header div {
  width: calc(100% * (1 / 3));
}
.prize-record-modal__wrapper {
  overflow: scroll;
}

.prize-record-modal__wrapper table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}
.prize-record-modal__wrapper thead {
  font-size: 14px;
}
.prize-record-modal__wrapper tbody {
  font-size: 12px;
  text-align: center;
}
.prize-record-modal__table-header th {
  border-bottom: 1px solid #ccc;
  font-weight: normal;
}
.prize-record-modal__table-row td {
  width: calc(100% * (1 / 3));
}
.prize-record-modal__table-row td:nth-child(-n + 2) {
  /* width: calc(100% * 0.4); */
  padding: 5px;
}
.prize-record-modal__redeem-button button,
.prize-record-modal__already-redeemed button {
  padding: 3px 0;
  border-radius: 5px;
  border: none;
  font-size: 8px;
  width: 60%;
  color: #fff;
  font-weight: normal;
}
.prize-record-modal__already-redeemed button {
  background-color: rgba(0, 0, 0, 0.2);
}
.prize-record-modal__redeem-button button {
  background-color: #4cd964;
}
.prize-record-modal__empty-record {
  height: 50px;
}
</style>