var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chouqian__wrapper"},[_c('img',{class:[
      _vm.isAnimationOn ? 'chouqian__sticks animation' : 'chouqian__sticks' ],attrs:{"src":_vm.chouqianSticks}}),_c('div',{staticClass:"chouqian__vessel-wrapper"},[_c('img',{staticClass:"chouqian__rim",attrs:{"src":_vm.chouqianRim}}),_c('div',{staticClass:"chouqian__vessel-color-square",style:({
        'background-color': _vm.gameDetails.mainColor
          ? _vm.gameDetails.mainColor
          : '#F4B789',
      })}),_c('div',{staticClass:"chouqian__vessel-color-trapezoid",style:({
        'border-top': _vm.gameDetails.mainColor
          ? ("33px solid " + (_vm.gameDetails.mainColor))
          : '33px solid #F4B789',
      })}),_c('img',{staticClass:"chouqian__vessel-image",attrs:{"src":_vm.chouqianContainer}})]),_c('div',{staticClass:"chouqian__start-game-button",on:{"click":function($event){return _vm.$emit('handleStartGame')}}},[_c('img',{attrs:{"src":_vm.startButtonImage}})])])}
var staticRenderFns = []

export { render, staticRenderFns }