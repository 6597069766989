<template>
  <div>
    <div v-show="!showQrcodeGameLink" class="line-authentication-page father qrcode-wrap">
      <div class="loading-indicator line-authentication-page__loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <!-- <div class="line-authentication-page__text child">LINE 身份驗證中</div> -->
    </div>
    <section v-show="showQrcodeGameLink">
      <div class="qrcode-wrap">
        <div ref="qrcode"></div>
      </div>
      <p class="qrcode-text">{{ $t('QRCODE_SCAN_DESCRIPTION_MESSAGE') }}</p>
    </section>
    <SweetModal
      ref="friendShipModal"
      :hide-close-button="true"
      overlay-theme="dark"
    >
      <div class="add-friend-modal__wrapper">
        <header class="modal-header__wrapper">
          <img
            @click="liffClose"
            :src="closeIcon"
            class="modal-header__close-button"
          />
          <div class="modal-header__title">{{ $t('ADD_FRIEND') }}</div>
        </header>
        <section class="add-friend-modal__text-area">
          <div>{{ $t('ADD_FRIEND_FIRST') }}，</div>
          <div>{{ $t('TO_GET_CHANCE') }}</div>
        </section>
        <section class="add-friend-modal__button-area">
          <button @click="showAddFriendPage" class="add-friend__button-add">
            {{ $t('ADD_FRIEND') }}
          </button>
          <button @click="liffClose" class="add-friend__button-cancel">
            {{ $t('CANCEL') }}
          </button>
        </section>
      </div>
    </SweetModal>
  </div>
</template>

<script>
import liff from '@line/liff'
import router from '../router'
import gameApi from '../restapi/game'
import { SweetModal } from 'sweet-modal-vue'
import * as QRCode from 'easyqrcodejs'
import isMobile from 'is-mobile'

import lineLogo from '../images/line_logo.png'
import closeIcon from '../images/icon-close.png'

export default {
  name: 'GetUserProfile',
  props: {
    gameId: String,
    userId: String,
    referrer: String,
    debug: Boolean,
    isModuleChannel: Boolean,
    platform: String,
  },
  data() {
    return {
      lineOaId: '',
      liffId: '',
      addFriendLink: `https://line.me/R/ti/p/@${this.lineOaId}`,
      isFriend: false,
      isLineClient: false,
      isUserInfoReady: false,
      // for modal
      closeIcon,
      gameLink: '',
      showQrcodeGameLink: false,
      qrcodeGameLink: ''
    }
  },
  async created() {
    if (this.$route.query.liffId) {
      await liff.init({ liffId: this.$route.query.liffId })
      if (new URL(location).searchParams.get('liff.state') !== null) {
        // 由於 SDK 尚未處理 liff.state 的跳轉
        // 所以透過一個不會結束的 Promise 避免程式繼續執行下去
        // eslint-disable-next-line no-unused-vars
        await new Promise(resolve => {})
      }
    }
    this.initAll()
  },
  watch: {
    isUserInfoReady() {
      if (!this.isModuleChannel) {
        // 非 module Channel
        if ((this.isFriend && this.isUserInfoReady) || this.platform !== 'line')
          return router.push('game-page')
      } else {
        // module channel 不檢查是否是好友
        if (this.isUserInfoReady) return router.push('game-page')
      }
    },
    lineOaId() {
      this.addFriendLink = `https://line.me/R/ti/p/@${this.lineOaId}`
    },
  },
  methods: {
    async getGameLineLiffId(gid) {
      if (this.gameId === '') return
      try {
        const res = await gameApi.getGameLiffId(gid)
        let data = res.data.data
        this.liffId = data.liffId
        this.$emit('liffIdHandler', data.liffId)
        return data
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response)
        } else {
          alert(`${this.$t('CAN_NOT_GET_LIFF_ID')}`)
        }
      }
    },
    async initAll() {
      try {
        if (this.platform === 'line') {
          const data = await this.getGameLineLiffId(this.gameId)
          console.log(data)
          if (!this.isModuleChannel) {
            // 若不是 module channel --> 走 init liff 路線
            if (data !== undefined) {
              this.lineOaId = data.oaId
              await liff.init({ liffId: data.liffId })
              this.initLine()
            } else {
              console.log('58 getGameLineLiffId is undefiend')
            }
          } else {
            // 若是 module channel --> 不用 LIFF ，直接從 url 上面拿 userId
            if (data) this.lineOaId = data.oaId
            this.isUserInfoReady = true
          }
        } else {
          this.isUserInfoReady = true
        }
      } catch (err) {
        console.log(err.code, err.message)
      }
    },
    async initLine() {
      // liff.ready 確保 init 必須執行完畢
      try {
        await liff.ready
        // alert(`location.href: ${location.href}`)
        // alert(`liffId: ${this.liffId}`)
        // alert(`gameId: ${this.gameId}`)
        if (liff.isInClient()) {
          this.isLineClient = true
          this.getFriendship()
        } else {

          if(isMobile()) {
            const redirectChannel = `https://line.me/R/app/${this.liffId}`
            let redirectOpenLineUrl = `${redirectChannel}?gid=${this.gameId}&platform=line`
            if(this.referrer) {
              redirectOpenLineUrl += `&r=${this.referrer}`
            }
            if(this.debug) {
              redirectOpenLineUrl += `&debug=${this.debug}`
            }
            // alert(`redirectOpenLineUrl: ${redirectOpenLineUrl}`)
            location.href = redirectOpenLineUrl
          } else {
            // 在 PC 用外部瀏覽器開啟遊戲連結，要直接 show QRCpde
            this.gameLink = `https://liff.line.me/${this.liffId}/?gid=${this.gameId}&platform=line`
            if(this.referrer) {
              this.gameLink += `&r=${this.referrer}`
            }
            if(this.debug) {
              this.gameLink += `&debug=${this.debug}`
            }
            this.showQrcodeGameLink = true
            var qroptions = {
              text: this.gameLink,
              width: 200,
              height: 200,
              logo: lineLogo,
              logoWidth: 40,
              logoHeight: 40,
            }

            new QRCode(this.$refs.qrcode, qroptions);
          }
          // alert(`${this.$t('OPEN_ON_LINE_INSTALLED')}`)
        }
      } catch (err) {
        console.log(err.code, err.message)
      }
    },
    getFriendship() {
      if (this.isFriend) return
      liff
        .getFriendship()
        .then(data => {
          if (typeof data !== 'undefined') {
            if (data.friendFlag) {
              this.isFriend = true
              console.log('99 is friend already')
              this.$refs.friendShipModal.close()
              if (!this.isUserInfoReady) this.getProfile()
            } else {
              console.log('97 not friend yet')
              this.$refs.friendShipModal.open()
            }
          }
        })
        .catch(err => {
          console.log(err.code, err.message)
        })
    },
    getProfile() {
      if (this.isUserInfoReady) return
      liff
        .getProfile()
        .then(response => {
          if (typeof response !== 'undefined') {
            console.log(response.userId)
            this.$emit('changeUserId', response.userId)
            this.isUserInfoReady = true
          }
        })
        .catch(err => {
          console.log(err.code, err.message)
        })
    },
    liffClose() {
      liff.closeWindow()
    },
    showAddFriendPage() {
      this.$refs.friendShipModal.close()
      window.location.href = this.addFriendLink
    },
  },
  components: {
    SweetModal,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.add-friend-modal__wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* justify-content: space-evenly; */
}
.add-friend-modal__text-area {
  text-align: center;
  margin: 70px 0;
}
.add-friend__button-add,
.add-friend__button-cancel {
  width: 200px;
  height: 44px;
  border-radius: 60px;
  border: none;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.add-friend__button-add {
  background-color: #4cd964;
  color: #fff;
  margin-bottom: 15px;
}
.add-friend__button-cancel {
  color: rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.qrcode-wrap {
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
}

.qrcode-text {
  text-align: center;
  padding: 20px 0 20px 0;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18.3px !important;
}
</style>
