import { apiHelper } from '../utils/helper';

export default {
  getGameLiffId(gid) {
    return apiHelper.get(`/restapi/v1/games/${gid}/liff-id`);
  },
  getGameDetails(gameId, userId, referrer) {
    return apiHelper.get(`/restapi/v1/games/${gameId}`
      , {
        params: {
          userId,
          ...(referrer ? { referrer } : {}),
        }
      })
  },
  startGame(gameId, userId) {
    return apiHelper.post(`/restapi/v1/games/${gameId}/start`, { userId })
  },
  getGamePrizeRecords(gameId, userId) {
    return apiHelper.get(`/restapi/v1/games/${gameId}/records`
      , {
        params: {
          userId
        }
      })
  },
  RedeemPrize(gameId, userId, recordId) {
    return apiHelper.post(`/restapi/v1/games/${gameId}/records`, { userId, recordId })
  }
};