<template>
  <div class="powered-by-omnichat-wrapper">
    <a
      class="powered-by-omnichat__link"
      href="https://www.omnichat.ai/"
      target="_blank"
    >
      <img class="powered-by-omnichat__icon" :src="omnichatLogo" alt="" />
      <div class="powered-by-omnichat">
        <div v-html="$t(`DEVELOPED_BY_OMNICHAT`)" />
      </div>
    </a>
  </div>
</template>

<script>
import omnichatLogo from '../images/omnichat-logo.png'

export default {
  name: 'OmnichatFooter',
  data() {
    return {
      omnichatLogo,
    }
  },
}
</script>

<style>
.powered-by-omnichat-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  z-index: 10;
  background-color: white;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.75);
  box-shadow: rgb(0 0 0 / 24%) -8px 1px 7px;
}
.powered-by-omnichat__link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  filter: grayscale(1);
  transition: 0.5s;
}
.powered-by-omnichat__link:hover .powered-by-omnichat__name {
  filter: none;
  color: rgba(0, 0, 0, 0.75);
}
.powered-by-omnichat__icon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
</style>