import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vconsole from 'vconsole'
import VueMoment from 'vue-moment';
import i18n from './plugins/i18n';

Vue.config.productionTip = false

if (location.href.indexOf('debug') !== -1) {
  const VConsole = new Vconsole()
  Vue.use(VConsole);
}

Vue.use(VueMoment);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
