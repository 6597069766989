<template>
  <div>
    <SweetModal
      icon="warning"
      ref="modal"
      class="error-modal successModal-icon"
      :hide-close-button="true"
      overlay-theme="dark"
      blocking
    >
      <h3>{{ $t('WRONG_URL') }}</h3>
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

export default {
  name: 'Errorpage',
  data() {
    return {}
  },

  mounted() {
    this.$refs.modal.open()
  },

  methods: {},
  components: {
    SweetModal,
  },
}
</script>

<style lang="scss">
.error-modal {
  .sweet-content{
    box-sizing: border-box;
    padding: 80px 20px !important;
  }
  .sweet-content-content {
    margin: 0;
  }
}
</style>