import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localeEn from './locale/en.json';
import localeZhHant from './locale/zh-Hant.json';
import localeZhHans from './locale/zh-Hans.json';

Vue.use(VueI18n)
const messages = {
  en: {
    ...localeEn,
  },
  zhHant: {
    ...localeZhHant
  },
  zhHans: {
    ...localeZhHans
  }
}

function getLang () {
  const language = navigator.languages && navigator.languages[0] ||
    navigator.language ||
    navigator.userLanguage;

  if (language.includes('zh-TW') || language.includes('zh-HK')) {
    return 'zhHant';
  } else if (language.includes('zh-CN')) {
    return 'zhHans';
  } else if (language.includes('en')) {
    return 'en';
  } else if(language.includes('zh')) {
    return 'zhHant';
  } else {
    return 'en';
  }
}

const lang = getLang() || 'zhHant'
const i18n = new VueI18n({
  locale: lang,
  messages,
})
export default i18n

// zh_Hant - 台灣
// zh_Hant - 香港
// zh_Hant - 澳門
// zh-Hans - 中國
// zh-Hans - 新加坡
// ['zh-TW', 'zh-CN', 'zh-HK']
