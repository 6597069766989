var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"capsule-toy__machine-wrapper"},[_c('div',{staticClass:"capsule-toy__machine-static-wrapper"},[_c('div',{staticClass:"capsule-toy__machine-image"},[_c('img',{attrs:{"src":_vm.capsuleMainImage}})]),_c('div',{staticClass:"capsule-toy__machine-color",style:({
        'background-color': _vm.gameDetails.mainColor
          ? _vm.gameDetails.mainColor
          : '#E95965',
      })}),_c('div',{class:_vm.wonCapsuleclass},[(_vm.isNoPrize)?_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.gameDetails.prizes[_vm.gameDetails.prizes.length - 1].image}}):_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.currentRoundPrizeDetails.prizeImage}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyBlue}})]),_c('div',{staticClass:"capsule-toy__capsule-group"},[_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule blue B-2 animation'
            : 'capsule-toy__each-capsule blue B-2' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[0]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyBlue}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule orange O-1 animation'
            : 'capsule-toy__each-capsule orange O-1' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[1]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyOrange}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule orange O-2 animation'
            : 'capsule-toy__each-capsule orange O-2' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[2]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyOrange}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule orange O-3 animation'
            : 'capsule-toy__each-capsule orange O-3' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[3]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyOrange}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule side O-4 animation'
            : 'capsule-toy__each-capsule side O-4' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[4]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyOrange}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule green G-1 animation'
            : 'capsule-toy__each-capsule green G-1' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[5]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyGreen}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule side G-2 animation'
            : 'capsule-toy__each-capsule side G-2' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[6]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyGreen}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule green G-3 animation'
            : 'capsule-toy__each-capsule green G-3' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[7]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyGreen}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule purple P-1 animation'
            : 'capsule-toy__each-capsule purple P-1' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[8]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyPurple}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule purple P-2 animation'
            : 'capsule-toy__each-capsule purple P-2' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[9]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyPurple}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule purple P-3 animation'
            : 'capsule-toy__each-capsule purple P-3' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[10]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyPurple}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule purple P-4 animation'
            : 'capsule-toy__each-capsule purple P-4' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[11]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyPurple}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule blue B-1 animation'
            : 'capsule-toy__each-capsule blue B-1' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[12]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyBlue}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule blue B-3 animation'
            : 'capsule-toy__each-capsule blue B-3' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[13]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyBlue}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule blue B-4 animation'
            : 'capsule-toy__each-capsule blue B-4' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[14]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyBlue}})]),_c('div',{class:[
          _vm.isAnimationOn
            ? 'capsule-toy__each-capsule blue B-5 animation'
            : 'capsule-toy__each-capsule blue B-5' ]},[_c('img',{staticClass:"capsule-toy__capsule-gift",attrs:{"src":_vm.prizeImgArr[15]}}),_c('img',{staticClass:"capsule-toy__capsule-case",attrs:{"src":_vm.capsuleToyBlue}})])])]),_c('div',{staticClass:"capsule-toy__start-game-button",on:{"click":function($event){return _vm.$emit('handleStartGame')}}},[_c('img',{attrs:{"src":_vm.startButtonImage}})])])}
var staticRenderFns = []

export { render, staticRenderFns }