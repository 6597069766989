<template>
  <div class="capsule-toy__machine-wrapper">
    <div class="capsule-toy__machine-static-wrapper">
      <div class="capsule-toy__machine-image">
        <img :src="capsuleMainImage" />
      </div>
      <div
        class="capsule-toy__machine-color"
        :style="{
          'background-color': gameDetails.mainColor
            ? gameDetails.mainColor
            : '#E95965',
        }"
      />
      <div :class="wonCapsuleclass">
        <img
          v-if="isNoPrize"
          :src="gameDetails.prizes[gameDetails.prizes.length - 1].image"
          class="capsule-toy__capsule-gift"
        />
        <img
          v-else
          :src="currentRoundPrizeDetails.prizeImage"
          class="capsule-toy__capsule-gift"
        />
        <img :src="capsuleToyBlue" class="capsule-toy__capsule-case" />
      </div>
      <div class="capsule-toy__capsule-group">
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule blue B-2 animation'
              : 'capsule-toy__each-capsule blue B-2',
          ]"
        >
          <img :src="prizeImgArr[0]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyBlue" class="capsule-toy__capsule-case" />
        </div>

        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule orange O-1 animation'
              : 'capsule-toy__each-capsule orange O-1',
          ]"
        >
          <img :src="prizeImgArr[1]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyOrange" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule orange O-2 animation'
              : 'capsule-toy__each-capsule orange O-2',
          ]"
        >
          <img :src="prizeImgArr[2]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyOrange" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule orange O-3 animation'
              : 'capsule-toy__each-capsule orange O-3',
          ]"
        >
          <img :src="prizeImgArr[3]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyOrange" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule side O-4 animation'
              : 'capsule-toy__each-capsule side O-4',
          ]"
        >
          <img :src="prizeImgArr[4]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyOrange" class="capsule-toy__capsule-case" />
        </div>

        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule green G-1 animation'
              : 'capsule-toy__each-capsule green G-1',
          ]"
        >
          <img :src="prizeImgArr[5]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyGreen" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule side G-2 animation'
              : 'capsule-toy__each-capsule side G-2',
          ]"
        >
          <img :src="prizeImgArr[6]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyGreen" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule green G-3 animation'
              : 'capsule-toy__each-capsule green G-3',
          ]"
        >
          <img :src="prizeImgArr[7]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyGreen" class="capsule-toy__capsule-case" />
        </div>

        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule purple P-1 animation'
              : 'capsule-toy__each-capsule purple P-1',
          ]"
        >
          <img :src="prizeImgArr[8]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyPurple" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule purple P-2 animation'
              : 'capsule-toy__each-capsule purple P-2',
          ]"
        >
          <img :src="prizeImgArr[9]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyPurple" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule purple P-3 animation'
              : 'capsule-toy__each-capsule purple P-3',
          ]"
        >
          <img :src="prizeImgArr[10]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyPurple" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule purple P-4 animation'
              : 'capsule-toy__each-capsule purple P-4',
          ]"
        >
          <img :src="prizeImgArr[11]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyPurple" class="capsule-toy__capsule-case" />
        </div>

        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule blue B-1 animation'
              : 'capsule-toy__each-capsule blue B-1',
          ]"
        >
          <img :src="prizeImgArr[12]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyBlue" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule blue B-3 animation'
              : 'capsule-toy__each-capsule blue B-3',
          ]"
        >
          <img :src="prizeImgArr[13]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyBlue" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule blue B-4 animation'
              : 'capsule-toy__each-capsule blue B-4',
          ]"
        >
          <img :src="prizeImgArr[14]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyBlue" class="capsule-toy__capsule-case" />
        </div>
        <div
          :class="[
            isAnimationOn
              ? 'capsule-toy__each-capsule blue B-5 animation'
              : 'capsule-toy__each-capsule blue B-5',
          ]"
        >
          <img :src="prizeImgArr[15]" class="capsule-toy__capsule-gift" />
          <img :src="capsuleToyBlue" class="capsule-toy__capsule-case" />
        </div>
      </div>
    </div>
    <div
      @click="$emit('handleStartGame')"
      class="capsule-toy__start-game-button"
    >
      <img :src="startButtonImage" />
    </div>
  </div>
</template>

<script>
import capsuleMainImage from '../images/capsule-main-image.png'
import capsuleToyBlue from '../images/capsule-toy-blue.png'
import capsuleToyGreen from '../images/capsule-toy-green.png'
import capsuleToyOrange from '../images/capsule-toy-orange.png'
import capsuleToyPurple from '../images/capsule-toy-purple.png'

export default {
  name: 'CapsuleToy',
  props: {
    isAnimationOn: Boolean,
    wonCapsuleclass: String,
    isNoPrize: Boolean,
    gameDetails: Object,
    currentRoundPrizeDetails: Object,
    startButtonImage: String
  },
  data() {
    return {
      capsuleMainImage,
      capsuleToyBlue,
      capsuleToyGreen,
      capsuleToyOrange,
      capsuleToyPurple,
    }
  },
  computed: {
    prizeCount() {
      console.log(this.gameDetails.prizes);
      return this.gameDetails.prizes.length
    },
    prizeImgArr() {
      const arr = []
      for (let i = 0; i < 16; i++) {
        const imageIndex = i % this.prizeCount
        const imageURL = this.gameDetails.prizes[imageIndex].image
        arr.push(imageURL)
      }
      return arr
    },
  },

  mounted() {},

  methods: {},
}
</script>

<style>
/* ================
capsule toy 扭蛋 - machine
================ */
.capsule-toy__main-container {
  position: relative;
  padding-bottom: 60px;
}
.capsule-toy__machine-wrapper {
  position: relative;
  width: 250px;
  margin: 0 auto;
  padding-top: 160px;
}
.capsule-toy__start-game-button {
  position: absolute;
  z-index: 10;
  bottom: 15px;
  width: 75px;
  left: 52px;
  cursor: pointer;
}
.capsule-toy__start-game-button img {
  width: 100%;
  max-height: 89px;
}
.capsule-toy__machine-static-wrapper {
  position: relative;
  z-index: -1;
}
.capsule-toy__machine-image {
  position: relative;
  z-index: 4;
}
.capsule-toy__machine-image img {
  width: 100%;
}
.capsule-toy__machine-color {
  position: absolute;
  width: 70.25%;
  height: 35.4%;
  bottom: 1%;
  left: 15%;
  border-radius: 3.5% 3.5% 0 0;
  z-index: 2;
}

/* ================
capsule toy 扭蛋 - each capsule
================ */
.capsule-toy__capsule-group {
  width: 91%;
  height: 57%;
  position: absolute;
  top: 12%;
  border-radius: 46%;
  left: 4%;
}
.capsule-toy__each-capsule {
  width: 19%;
  position: absolute;
}
.capsule-toy__capsule-gift {
  position: absolute;
  left: 10%;
  z-index: -1;
  width: 80%;
  margin-top: 5px;
}
.capsule-toy__capsule-case {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;
}
.capsule-toy__each-capsule.G-1 {
  bottom: 56%;
  left: 7%;
  transform: rotate(50deg);
}
.capsule-toy__each-capsule.G-1.animation {
  animation: animation-G-1 1s infinite both;
}
@keyframes animation-G-1 {
  50% {
    transform: rotate(75deg);
  }
}
.capsule-toy__each-capsule.G-2 {
  bottom: 11%;
  left: 22%;
  transform: rotate(-165deg);
}
.capsule-toy__each-capsule.G-2.animation {
  animation: animation-G-2 1s infinite both;
}
@keyframes animation-G-2 {
  50% {
    transform: rotate(-190deg);
  }
}
.capsule-toy__each-capsule.G-3 {
  bottom: 56%;
  left: 39%;
  transform: rotate(-45deg);
}
.capsule-toy__each-capsule.G-3.animation {
  animation: animation-G-3 1s infinite both;
}
@keyframes animation-G-3 {
  50% {
    transform: rotate(-70deg);
  }
}
.capsule-toy__each-capsule.P-1 {
  bottom: 40%;
  left: 3%;
  transform: rotate(-20deg);
}
.capsule-toy__each-capsule.P-1.animation {
  animation: animation-P-1 1s infinite both;
}
@keyframes animation-P-1 {
  50% {
    transform: rotate(-30deg);
  }
}
.capsule-toy__each-capsule.P-2 {
  bottom: 49%;
  left: 28%;
  transform: rotate(190deg);
}
.capsule-toy__each-capsule.P-2.animation {
  animation: animation-P-2 1s infinite both;
}
@keyframes animation-P-2 {
  50% {
    transform: rotate(170deg);
  }
}
.capsule-toy__each-capsule.P-3 {
  bottom: 38%;
  left: 47%;
  transform: rotate(-70deg);
}
.capsule-toy__each-capsule.P-3.animation {
  animation: animation-P-3 1s infinite both;
}
@keyframes animation-P-3 {
  50% {
    transform: rotate(-80deg);
  }
}
.capsule-toy__each-capsule.P-4 {
  bottom: 69%;
  left: 74%;
  transform: rotate(-35deg);
}
.capsule-toy__each-capsule.P-4.animation {
  animation: animation-P-4 1s infinite both;
}
@keyframes animation-P-4 {
  50% {
    transform: rotate(-10deg);
  }
}
.capsule-toy__each-capsule.O-1 {
  bottom: 55%;
  left: 22%;
  transform: rotate(35deg);
}
.capsule-toy__each-capsule.O-1.animation {
  animation: animation-O-1 1s infinite both;
}
@keyframes animation-O-1 {
  50% {
    transform: rotate(55deg);
  }
}
.capsule-toy__each-capsule.O-2 {
  bottom: 32%;
  left: 46%;
  transform: rotate(20deg);
}
.capsule-toy__each-capsule.O-2.animation {
  animation: animation-O-2 1s infinite both;
}
@keyframes animation-O-2 {
  50% {
    transform: rotate(0deg);
  }
}
.capsule-toy__each-capsule.O-3 {
  bottom: 79%;
  left: 48%;
  transform: rotate(-20deg);
}
.capsule-toy__each-capsule.O-3.animation {
  animation: animation-O-3 1s infinite both;
}
@keyframes animation-O-3 {
  50% {
    transform: rotate(0deg);
  }
}
.capsule-toy__each-capsule.O-4 {
  bottom: 31%;
  left: 84%;
  transform: rotate(150deg);
}
.capsule-toy__each-capsule.O-4.animation {
  animation: animation-O-4 1s infinite both;
}
@keyframes animation-O-4 {
  50% {
    transform: rotate(125deg);
  }
}
.capsule-toy__each-capsule.B-1 {
  bottom: 57%;
  left: 16%;
  transform: rotate(150deg);
}
.capsule-toy__each-capsule.B-1.animation {
  animation: animation-B-1 1s infinite both;
}
@keyframes animation-B-1 {
  50% {
    transform: rotate(125deg);
  }
}
.capsule-toy__each-capsule.B-2 {
  bottom: 47%;
  left: 39%;
  transform: rotate(40deg);
}
.capsule-toy__each-capsule.B-2.animation {
  animation: animation-B-2 1s infinite both;
}
@keyframes animation-B-2 {
  50% {
    transform: rotate(60deg);
  }
}
.capsule-toy__each-capsule.B-3 {
  bottom: 90%;
  left: 41%;
  transform: rotate(25deg);
}
.capsule-toy__each-capsule.B-3.animation {
  animation: animation-B-3 1s infinite both;
}
@keyframes animation-B-3 {
  50% {
    transform: rotate(-25deg);
  }
}
.capsule-toy__each-capsule.B-4 {
  bottom: 60%;
  left: 57%;
  transform: rotate(-55deg);
}
.capsule-toy__each-capsule.B-4.animation {
  animation: animation-B-4 1s infinite both;
}
@keyframes animation-B-4 {
  50% {
    transform: rotate(-70deg);
  }
}
.capsule-toy__each-capsule.B-5 {
  bottom: 33%;
  left: 68%;
  transform: rotate(-15deg);
}
.capsule-toy__each-capsule.B-5.animation {
  animation: animation-B-5 1s infinite both;
}
@keyframes animation-B-5 {
  50% {
    transform: rotate(0deg);
  }
}

.capsule-toy__won-capsule {
  position: absolute;
  width: 15%;
  right: 25%;
  bottom: 20%;
  z-index: 4;
  display: none;
}
.capsule-toy__won-capsule.scale-in-top {
  display: block;
}
.scale-in-top {
  -webkit-animation: scale-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
</style>