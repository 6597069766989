var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SweetModal',{ref:"modal",attrs:{"hide-close-button":true,"overlay-theme":"dark","blocking":""}},[_c('div',{staticClass:"loading-indicator",style:({ display: _vm.displayLoading ? 'block' : 'none' })},[_c('div'),_c('div'),_c('div')]),_c('div',{staticClass:"scratch-card__modal-wrapper"},[_c('h3',{staticClass:"scratch-card__modal-content"},[_vm._v(_vm._s(_vm.$t('READY_TO_SCRATCH')))]),_c('button',{staticClass:"scratch-card__start-game-button",on:{"click":_vm.handleStartGame}},[_vm._v(" "+_vm._s(_vm.$t('UNLOCK_GAME'))+" ")])])]),_c('div',{staticClass:"scratch-card__card-wrapper"},[_c('img',{class:[
        _vm.noRemainingDraws
          ? 'scratch-card__main-image hidden'
          : 'scratch-card__main-image' ],attrs:{"src":_vm.mainImage}}),_c('div',{class:[
        _vm.noRemainingDraws
          ? 'scratch-card__canvas-wrapper hidden'
          : 'scratch-card__canvas-wrapper' ]},[_c('canvas',{ref:"canvas",staticClass:"scratch-card__canvas",style:({ width: 260, height: 208 })}),_c('img',{staticClass:"scratch-card__prize",style:({ maxWidth: 260, maxHeight: 208 }),attrs:{"src":_vm.currentRoundPrizeImage}}),_c('div',{staticClass:"scratch-card__hand-cursor diagonal-animation",style:({ display: _vm.displayHandCursor ? 'block' : 'none' })},[_c('img',{attrs:{"src":_vm.handCursorImage}})])]),_c('div',{staticClass:"scratch-card__no-remaining-draws",style:({ display: _vm.noRemainingDraws ? 'flex' : 'none' })},[_c('div',{staticClass:"no-draws-wrapper"},[_c('p',[_vm._v(_vm._s(_vm.gameStatusMsg))])]),_c('div',{staticClass:"scratch-card__no-remaining-draws-share",style:({
          display: !_vm.isGameDraft && _vm.gameDetails.enableShare ? 'block' : 'none',
        })},[_vm._v(" "+_vm._s(_vm.$t('SHARE_WITH_FRIEND_3'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('EARN_GAME_CHANCE'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }