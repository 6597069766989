<template>
  <div id="app">
    <router-view
      :gameId="gameId"
      :userId="userId"
      :isModuleChannel="isModuleChannel"
      :referrer="referrer"
      :platform="platform"
      :liffId="liffId"
      :debug="debug"
      @changeUserId="userIdHandler"
      @liffIdHandler="liffIdHandler"
    >
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      gameId: this.$route.query.gid,
      userId: this.$route.query.uid,
      referrer: this.$route.query.r,
      debug: this.$route.query.debug,
      liffId:'',
      // ===== shared by all games - For LINE marketplace
      isModuleChannel: false,
      platform: this.$route.query.platform || 'line',
    }
  },
  created() {
    if (this.$route.query.moduleChannel) {
      this.isModuleChannel = JSON.parse(this.$route.query.moduleChannel)
    }
    if (this.$route.query.debug) {
      this.debug = true
    }
  },

  methods: {
    userIdHandler(userId) {
      this.userId = userId
    },
    liffIdHandler(liffId) {
      this.liffId = liffId
    }
  },
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
html,
body {
  height: 100vh;
  margin: 0;
  font-family: ‘cwTeXYen’, sans-serif;
  width: 100%;
}

body {
  box-sizing: border-box;
}

h1,
p {
  font-family: Lato;
}

.sweet-modal.is-mobile-fullscreen {
  height: auto!important;
}

.loading-indicator {
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  margin: auto;
}
.loading-indicator div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #aaa;
  animation: loading__animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading-indicator div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading-indicator div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading-indicator div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading__animation {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.sweet-modal-overlay.theme-dark {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.sweet-modal {
  top: 30% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 80%;
  margin-top: 20vh !important;
  border-radius: 12px !important;
  height: auto;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  padding: 0px !important;
  padding-bottom: 8px;
}
.sweet-modal .sweet-content {
  box-sizing: border-box;
  padding: 40px 20px !important;
}
.sweet-modal-icon {
  /* margin-top: 50% !important; */
  top: 20%;
}
.sweet-content,
.sweet-content-content {
  height: 100%;
}

.shareModal-icon .sweet-content-content,
.successModal-icon .sweet-content-content {
  height: auto;
  font-size: 13px;
  padding: 0 20px;
  color: gray;
}

.alertModal-title {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  font-size: 16px;
}

.won-prize-modal__wrapper,
.prize-record-modal__wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  border-radius: 12px !important;
}

.modal-header__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.modal-header__close-button,
.no-prize-modal__close-button,
.won-prize-modal__close-button {
  text-align: right;
  width: 20px;
  align-self: flex-end;
  margin: 10px 10px 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.modal-header__title {
  font-size: 20px;
  font-weight: 700;
}
</style>
