<template>
  <div>
    <SweetModal
      ref="modal"
      :hide-close-button="true"
      overlay-theme="dark"
      blocking
    >
      <div
        class="loading-indicator"
        :style="{ display: displayLoading ? 'block' : 'none' }"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="scratch-card__modal-wrapper">
        <h3 class="scratch-card__modal-content">{{ $t('READY_TO_SCRATCH') }}</h3>
        <button
          class="scratch-card__start-game-button"
          @click="handleStartGame"
        >
          {{ $t('UNLOCK_GAME') }}
        </button>
      </div>
    </SweetModal>
    <div class="scratch-card__card-wrapper">
      <img
        :class="[
          noRemainingDraws
            ? 'scratch-card__main-image hidden'
            : 'scratch-card__main-image',
        ]"
        :src="mainImage"
      />
      <div
        :class="[
          noRemainingDraws
            ? 'scratch-card__canvas-wrapper hidden'
            : 'scratch-card__canvas-wrapper',
        ]"
      >
        <canvas
          ref="canvas"
          :style="{ width: 260, height: 208 }"
          class="scratch-card__canvas"
        />
        <img
          :src="currentRoundPrizeImage"
          :style="{ maxWidth: 260, maxHeight: 208 }"
          class="scratch-card__prize"
        />
        <div
          class="scratch-card__hand-cursor diagonal-animation"
          :style="{ display: displayHandCursor ? 'block' : 'none' }"
        >
          <img :src="handCursorImage" />
        </div>
      </div>
      <div
        :style="{ display: noRemainingDraws ? 'flex' : 'none' }"
        class="scratch-card__no-remaining-draws"
      >
        <div class="no-draws-wrapper">
          <p>{{ gameStatusMsg }}</p>
        </div>
        <div
          :style="{
            display: !isGameDraft && gameDetails.enableShare ? 'block' : 'none',
          }"
          class="scratch-card__no-remaining-draws-share"
        >
          {{ $t('SHARE_WITH_FRIEND_3') }}
          <br />
          {{ $t('EARN_GAME_CHANCE') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

import handCursorImage from '../images/hand-cursor.png'
import scratchBrush from '../images/scratch-brush.png'
import capsuleToyGift from '../images/capsule-toy-gift.png'

export default {
  name: 'Scratchoff',
  props: {
    gameDetails: Object,
    remainingDraws: Number,
    secondaryImage: String,
    mainImage: String,
    postStartGame: Function,
    isGameDraft: Boolean,
    displayScratchPlayAgain: Boolean,
    currentRoundPrizeDetails: Object,
    GAME_STATUS: Object,
  },
  data() {
    return {
      ctx: null,
      isDrawing: false,
      displayHandCursor: false,
      hasGameFinished: false,
      scratchPercent: 25,
      // ===== scratch specific
      displayScratchLoading: false,
      showModal: false,
      displayLoading: false,
      currentRoundPrizeImage: '',
      noRemainingDraws: false,
      gameStatusMsg: '',
      devicePixelRatio: 1,
      handCursorImage,
      scratchBrush,
      capsuleToyGift
    }
  },
  mounted() {
    setTimeout(() => {
      // 0 draft, 4 progressing
      if (
        this.gameDetails.status === this.GAME_STATUS.isProcessing ||
        this.gameDetails.status === this.GAME_STATUS.isGameDraft
      ) {
        if (this.remainingDraws > 0) {
          this.noRemainingDraws = false
          this.$refs.modal.open()
        } else {
          this.noRemainingDraws = true
          this.gameStatusMsg = this.$t('DRAWS_USED_UP')
        }
      } else {
        this.noRemainingDraws = true
        this.gameStatusMsg = this.$t('GAME_UNPUBLISHED')
      }
    }, 250)
    const canvas = this.$refs['canvas']

    this.devicePixelRatio = window.devicePixelRatio || 1;

    // 根據螢幕解析度設定 Canvas 大小
    canvas.width = canvas.parentElement.offsetWidth * this.devicePixelRatio
    canvas.height = canvas.parentElement.offsetHeight * this.devicePixelRatio
    // Canvas 實際在瀏覽器的大小
    // canvas.style.width = `${canvas.parentElement.offsetWidth}px`
    // canvas.style.height = `${canvas.parentElement.offsetHeight}px`

    canvas.addEventListener('mousedown', this.touchStart)
    canvas.addEventListener('touchstart', this.touchStart)
    canvas.addEventListener('mousemove', this.touchMove)
    canvas.addEventListener('touchmove', this.touchMove)
    canvas.addEventListener('mouseup', this.touchEnd)
    canvas.addEventListener('touchend', this.touchEnd)

    this.ctx = canvas.getContext('2d')
    this.ctx.crossOrigin = 'Anonymous'

    this.brush = new Image()
    this.brush.src = scratchBrush

    this.brush.crossOrigin = 'Anonymous'

    this.cover = new Image()
    this.cover.crossOrigin = 'Anonymous'
    this.cover.src = this.secondaryImage
    console.log(`${JSON.parse(JSON.stringify(this.cover.src))} first`)

    // setTimeout(() => {
    //   if (this.secondaryImage != '') {
    //     this.cover.src = this.secondaryImage
    //     console.log(`secondaryImage :${this.secondaryImage} `)
    //     console.log(`${this.cover.src} sec`)
    //   } else {
    //     this.cover.src = scratchSecondaryImage
    //     console.log(`${this.cover.src} third`)
    //   }
    // }, 250)

    this.cover.onload = () => this.ctx.drawImage(this.cover, 0, 0, canvas.width, canvas.height)
    this.cover.onerror = () => console.log('image load error')
  },
  beforeDestroy() {
    const canvas = this.$refs['canvas']
    canvas.removeEventListener('mousedown', this.touchStart)
    canvas.removeEventListener('touchstart', this.touchStart)
    canvas.removeEventListener('mousemove', this.touchMove)
    canvas.removeEventListener('touchmove', this.touchMove)
    canvas.removeEventListener('mouseup', this.touchEnd)
    canvas.removeEventListener('touchend', this.touchEnd)
  },
  methods: {
    async handleStartGame() {
      try {
        this.displayLoading = true
        let res = await this.postStartGame()
        if (res) {
          if (res.prizeImage) {
            this.currentRoundPrizeImage = res.prizeImage
          } else {
            this.currentRoundPrizeImage = capsuleToyGift
          }
        }
        setTimeout(() => {
          this.displayLoading = false
          this.$refs.modal.close()
          this.displayHandCursor = true
        }, 1000)
      } catch (err) {
        console.log('scratch handleStartGame: ')
        console.log(err.code, err.message)
        this.hasGameFinished = true
        alert(`${this.$t('UNABLE_START_GAME')}`)
      }
    },

    // canvas drawing
    checkFilledPercent() {
      // https://stackoverflow.com/questions/43928602/html-canvas-get-total-fill-in-percent/43938430
      // use getImageData and check the total number of pixels that is transparent
      const data = this.ctx.getImageData(
        0,
        0,
        this.$refs['canvas'].parentElement.offsetWidth,
        this.$refs['canvas'].parentElement.offsetHeight
      ).data
      const nrOfPixels = data.length / 4 // rgba pixels
      let transparent = 0
      for (let i = 3; i < data.length; i += 4) {
        transparent += data[i] ? 0 : 1
      }
      const percentage = (transparent / nrOfPixels) * 100
      if (this.gameDetails !== undefined) {
        if (
          this.gameDetails.status === this.GAME_STATUS.isProcessing ||
          this.gameDetails.status === this.GAME_STATUS.isGameDraft
        ) {
          if (percentage > this.scratchPercent) {
            this.currentRoundPrizeDetails.noPrize
              ? this.$emit('handleModalStatus', false)
              : this.$emit('handleModalStatus', true)
            this.hasGameFinished = true

            if (this.currentRoundPrizeDetails.noPrize) {
              // 未中獎後端處理
              // this.$emit(
              //   'handleRedeemPrize',
              //   this.currentRoundPrizeDetails.prizeRecordId,
              //   true
              // )
            }

            if (this.remainingDraws > 0) {
              this.$emit('handleScratchPlayAgain')
            }
          } else {
            this.displayHandCursor = true
          }
        }
      }
    },

    getPosition(event) {
      let target = this.$refs.canvas
      let offsetX = 0
      let offsetY = 0

      if (target.offsetParent !== undefined) {
        // eslint-disable-next-line no-cond-assign
        while ((target = target.offsetParent)) {
          offsetX += target.offsetLeft
          offsetY += target.offsetTop
        }
      }

      const x = ((event.pageX || event.touches[0].pageX) - offsetX) * this.devicePixelRatio * 0.7
      const y = ((event.pageY || event.touches[0].pageY) - offsetY) * this.devicePixelRatio * 0.9
      return { x, y }
    },

    touchStart(event) {
      if (this.hasGameFinished) return
      this.displayHandCursor = false
      this.isDrawing = true
      this.lastPoint = this.getPosition(event)
      this.ctx.globalCompositeOperation = 'destination-out'
    },

    touchMove(event) {
      if (!this.isDrawing || this.hasGameFinished) return
      event.preventDefault()
      const ctx = this.ctx
      const a = this.lastPoint
      const b = this.getPosition(event)
      const dist = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2))
      const angle = Math.atan2(b.x - a.x, b.y - a.y)
      const offsetX = this.brush.width / 2
      const offsetY = this.brush.height / 2

      for (let x, y, i = 0; i < dist; i++) {
        x = a.x + Math.sin(angle) * i - offsetX
        y = a.y + Math.cos(angle) * i - offsetY
        ctx.drawImage(this.brush , x, y, this.brush.width * this.devicePixelRatio * 3 , this.brush.height * this.devicePixelRatio * 3)
      }
      this.lastPoint = b
    },

    touchEnd() {
      if (this.hasGameFinished) return
      this.checkFilledPercent()
      this.isDrawing = false
    },
  },
  components: {
    SweetModal,
  },
}
</script>

<style>
.scratch-card__card-wrapper {
  position: relative;
  width: 300px;
  max-height: 617px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 160px;
}
.scratch-card__main-image {
  width: 100%;
  max-height: 457px;
  border-radius: 15px;
}
.scratch-card__main-image.hidden {
  visibility: hidden;
}
.scratch-card__canvas-wrapper {
  position: absolute;
  width: 260px;
  height: 208px;
  cursor: pointer;
  bottom: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.scratch-card__canvas-wrapper.hidden {
  visibility: hidden;
}
.scratch-card__canvas {
  position: absolute;
  border-radius: inherit;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.scratch-card__prize {
  position: absolute;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.scratch-card__no-remaining-draws {
  position: absolute;
  bottom: 150px;
  font-size: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  width: 100%;
}
.scratch-card__no-remaining-draws-share {
  font-size: 16px;
  margin-top: 30px;
}

/* ================
scratch card - hand cursor animation
================ */
.scratch-card__hand-cursor {
  position: absolute;
  width: 50px;
  z-index: 2;
  bottom: 20px;
  left: 30px;
  user-select: none;
}
.scratch-card__hand-cursor img {
  width: 100%;
}
.diagonal-animation {
  -webkit-animation: diagonal-animation 4s cubic-bezier(0.39, 0.575, 0.565, 1)
    infinite both;
  animation: diagonal-animation 4s cubic-bezier(0.39, 0.575, 0.565, 1) infinite
    both;
}
@-webkit-keyframes diagonal-animation {
  0%,
  100% {
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  50% {
    -webkit-transform: translateX(140px) translateY(-100px);
    transform: translateX(140px) translateY(-100px);
  }
}
@keyframes diagonal-animation {
  0%,
  100% {
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  50% {
    -webkit-transform: translateX(140px) translateY(-100px);
    transform: translateX(140px) translateY(-100px);
  }
}

/* ================
scratch card - init modal
================ */
.loading-indicator {
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  margin: auto;
}
.loading-indicator div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #aaa;
  animation: loading__animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading-indicator div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading-indicator div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading-indicator div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading__animation {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.no-draws-wrapper {
  padding: 4px 12px;
  border-radius: 4px;
  background-color: #FFFFFFCC;
  font-family: 'Noto Sans TC';
  font-weight: 500;
  font-size: 16px;
  line-height: 23.17px;
}
.scratch-card__modal-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
}
.scratch-card__start-game-button {
  background-color: #00b900;
  border: 1px solid #00b900;
  color: #fff;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
