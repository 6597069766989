<template>
  <div class="machine-wrapper">
    <div
      class="winwheel-wrapper"
      :style="{ background: gameDetails.mainColor }"
    >
      <div class="winwheel__shadow" />
      <div ref="winwheelRef" class="winwheel">
        <div
          class="winwheel__fan"
          v-for="(prize, index) in gameDetails.prizes"
          :key="prize.id"
          :style="getFanStyle(prize, index)"
        />
        <div
          class="prize"
          v-for="(prize, index) in gameDetails.prizes"
          :style="getPrizeStyle(prize, index)"
          :key="index"
        >
          <img v-if="prize.image" class="prize__img" :src="prize.image" />
          <img v-else class="prize__img" :src="sadFaceImage" />
          <div class="prize__name" :style="{ color: prize.textColor }">
            {{ prize.name }}
          </div>
        </div>
      </div>
      <img
        ref="winwheelOutsideRef"
        class="winwheel__outside"
        :src="winwheelOutside"
        alt=""
      />
      <img
        @click="handleStartGame"
        :src="gameDetails.secondaryImage"
        class="winwheel__pointer"
        alt=""
      />
    </div>
    <div class="winwheel__base-wrapper">

    </div>
  </div>
</template>

<script>
import winwheelOutside from '../images/winwheel/winwheel-outside.png'
import winwheelBase from '../images/winwheel/winwheel-base-red.png'
import sadFaceImage from '../images/face-sad.png'

export default {
  name: 'WinWheel',
  props: {
    gameDetails: Object,
    isAnimationOn: Boolean,
    postStartGame: Function,
  },
  data() {
    return {
      isGameProcessing: false,
      startDegree: 0,
      winwheelOutside,
      winwheelBase,
      sadFaceImage,
    }
  },
  computed: {
    fanDegree() {
      return 360 / this.gameDetails.prizes.length
    },
    prizeDegreeArray() {
      let array = []
      for (let i = 0; i < this.gameDetails.prizes.length; i++) {
        array.push(this.fanDegree * i)
      }
      return array
    },
  },
  methods: {
    getFanStyle(prize, index) {
      let fanOffsetDeg = this.fanDegree / 2
      let skewDeg = this.fanDegree - 90
      let rotateDeg = this.fanDegree * index - fanOffsetDeg
      return {
        background: prize.bgColor,
        transform: `rotate(${rotateDeg}deg) skewY(${skewDeg}deg)`,
      }
    },

    getPrizeStyle(prize, index) {
      return {
        transform: `translateX(50%) rotate(${this.prizeDegreeArray[index]}deg)`,
      }
    },

    // 指針開始轉，轉到得到的獎品為止
    startRotate(winPrize) {
      let winPrizeIndex = this.gameDetails.prizes.findIndex(
        prize => prize.id === winPrize.prizeId
      )
      let prizeDegree = this.prizeDegreeArray[winPrizeIndex]

      // 旋轉的角度 = 目前的角度 - 多轉四圈 - 獎項所在角度 - 目前指針偏移零度多少
      let rotateDeg =
        this.startDegree - 1440 - prizeDegree - (this.startDegree % 360)
      this.$refs.winwheelRef.style.transform = `rotate(${rotateDeg}deg)`
      this.$refs.winwheelOutsideRef.style.transform = `rotate(${rotateDeg}deg)`
      this.startDegree = rotateDeg

      setTimeout(() => {
        if (winPrize.noPrize) {
          // redeem noPrize
          this.$emit('handleModalStatus', false)
          // 未中獎後端處理
          // this.$emit('handleRedeemPrize', winPrize.prizeRecordId, true)
        } else {
          this.$emit('handleModalStatus', true)
        }
        this.isGameProcessing = false
      }, 6000)
    },

    async handleStartGame() {
      if (this.isGameProcessing) return
      this.isGameProcessing = true

      let winPrize = await this.postStartGame()

      if (winPrize) {
        this.startRotate(winPrize)
      } else {
        this.isGameProcessing = false
      }
    },
  },
}
</script>

<style>
.machine-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.winwheel-wrapper {
  width: 300px;
  height: 300px;
  position: relative;
  margin:200px auto 0;
  border-radius: 50%;
  background: #ff9700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winwheel {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: transform 6s;
  transform-origin: center;
}

.winwheel__shadow {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 2;
  background: transparent;
}

.winwheel__fan {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 50%;
  z-index: 2;
  transform-origin: left bottom;
}

.winwheel__outside {
  position: absolute;
  /* display: none; */
  width: 100%;
  height: 100%;
  top: 0;
  transition: transform 6s;
}

.winwheel__pointer {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  right: 50%;
  /* Pointer 本身的預設偏移角度 */
  /* transform: translate(50%, -50%) rotate(-45deg); */
  transform: translate(50%, -50%);
  transition: transform 3s;
  z-index: 3;
}

.prize {
  /* border: 1px solid; */
  width: 60px;
  height: 48%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  transform-origin: 50% 100%;
  top: 0;
  right: 50%;
  padding-top: 5px;
}

.prize__img {
  width: 40px;
  height: 40px;
}

.prize__name {
  font-size: 12px;
  text-align: center;
  margin-top: 2px;
  font-weight: 500;
}

.winwheel__base-wrapper {
  z-index: 1;
  height: 65px;
}
</style>