import axios from 'axios';

const baseURL = process.env.VUE_APP_API_END_POINT;

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true
});

export const apiHelper = axiosInstance;
