<template>
  <div>
    <div v-if="!gameDetails.type" class="loading-indicator">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div v-else class="games__main-container">
      <div
        class="main-background-image"
        :style="{
          'background-color': bgColor,
          'background-image': bgImage,
        }"
      />
      <SlotMachine
        v-if="gameDetails.type === GAME_TYPE.SlotMachine"
        :gameDetails="gameDetails"
        :postStartGame="postStartGame"
        @handleRedeemPrize="handleRedeemPrize"
        @handleModalStatus="handleModalStatus"
      />
      <CapsuleToy
        v-if="gameDetails.type === GAME_TYPE.CapsuleToy"
        :isAnimationOn="isAnimationOn"
        :gameDetails="gameDetails"
        :isNoPrize="isNoPrize"
        :wonCapsuleclass="wonCapsuleclass"
        :currentRoundPrizeDetails="currentRoundPrizeDetails"
        :startButtonImage="startButtonImage"
        @handleStartGame="handleStartGame"
      />
      <ChouqianHooks
        v-if="gameDetails.type === GAME_TYPE.ChouqianHooks"
        :isAnimationOn="isAnimationOn"
        :gameDetails="gameDetails"
        :startButtonImage="startButtonImage"
        @handleStartGame="handleStartGame"
      />
      <ScratchOff
        v-if="gameDetails.type === GAME_TYPE.ScratchOff"
        :gameDetails="gameDetails"
        :isGameDraft="isGameDraft"
        :currentRoundPrizeDetails="currentRoundPrizeDetails"
        :mainImage="mainImage"
        :remainingDraws="remainingDraws"
        :secondaryImage="secondaryImage"
        :postStartGame="postStartGame"
        :GAME_STATUS="GAME_STATUS"
        @handleRedeemPrize="handleRedeemPrize"
        @handleModalStatus="handleModalStatus"
        @handleScratchPlayAgain="handleScratchPlayAgain"
      />
      <WinWheel
        v-if="gameDetails.type === GAME_TYPE.WinWheel"
        :gameDetails="gameDetails"
        :postStartGame="postStartGame"
        :isAnimationOn="isAnimationOn"
        @handleRedeemPrize="handleRedeemPrize"
        @handleModalStatus="handleModalStatus"
      />
      <UserGameData
        :gameDetails="gameDetails"
        :handlePlayAgain="handlePlayAgain"
        :displayScratchPlayAgain="displayScratchPlayAgain"
        :shareLineMessage="shareLineMessage"
        :isGameDraft="isGameDraft"
      />
      <GameRuleAndPrize
        :remainingDraws="remainingDraws"
        :gameDetails="gameDetails"
        @handleRecordsModal="handleRecordsModal"
      />
      <SweetModal
        ref="WonPrizeModal"
        width="330"
        :hide-close-button="true"
        overlay-theme="dark"
      >
        <template slot="box-action">
          <img
            @click="handlePlayAgain(true)"
            :src="closeIcon"
          />
        </template>
        <div class="won-prize-modal__wrapper">
          <section class="won-prize-modal__top-area">
            <img
              v-if="currentRoundPrizeDetails.prizeImage"
              :src="currentRoundPrizeDetails.prizeImage"
              class="won-prize-modal__prize-image"
            />
            <img
              v-else class="won-prize-modal__image"
              src="https://s3-ap-southeast-1.amazonaws.com/caas-media-storage/upload/photos/LINE_Game_Default_Image/common/prize_result_win.png">
            <div class="won-prize-modal__title">
              <span v-if="gameDetails.winTitle">
                {{ gameDetails.winTitle }}
              </span>
              <span v-else>
                {{ $t('CONGRATULATIONS') }}!
              </span>
            </div>
            <div class="won-prize-modal__subtitle">
              <span v-show="gameDetails.winSubtitle">{{ gameDetails.winSubtitle }}</span>
              <span v-show="currentRoundPrizeDetails.prizeName" class="won-prize-modal__prize-name">{{currentRoundPrizeDetails.prizeName}}
              </span>
            </div>
          </section>
          <section class="won-prize-modal__bottom-area">
            <div class="prize-modal__draft-warning"
              :style="{ display: isGameDraft ? 'block' : 'none' }"
            >
              {{ $t('IN_BETA') }}
              <br />
              {{ $t('PRIZE_CAN_NOT_REDEEMED') }}
            </div>
              <button
                @click="
                  handleRedeemPrize(
                    currentRoundPrizeDetails.prizeRecordId,
                    false,
                    false
                  )
                "
                class="won-prize-modal__redeem-prize-button"
              >
                <span v-if="gameDetails.winButtonTitle">{{ gameDetails.winButtonTitle }}</span>
                <span v-else>{{ $t('REDEEM_NOW') }}</span>
              </button>
            <button
              v-show="gameDetails.enableShare"
              @click="shareLineMessage"
              class="won-prize-modal__share-to-friends-button"
            >
              <img :src="shareButtonIconPrimary" />
              {{ $t('FRIEND') }}
            </button>
            <div
              v-show="gameDetails.enableShare"
              class="won-prize-modal__share-to-friends-text"
            >
              {{ $t('SHARE_WITH_FRIEND') }}！
            </div>
          </section>
        </div>
      </SweetModal>
      <SweetModal
        ref="NoPrizeModal"
        :hide-close-button="true"
        overlay-theme="dark"
        width="330px"
      >
        <template slot="box-action">
          <img
            @click="handlePlayAgain(true)"
            :src="closeIcon"
          />
        </template>
        <div class="no-prize-modal__wrapper">
          <section class="no-prize-modal__top-area">
            <img
              v-if="currentRoundPrizeDetails.prizeImage"
              :src="currentRoundPrizeDetails.prizeImage"
              class="no-prize-modal__image"
            />
            <img
              v-else class="no-prize-modal__image"
              src="https://s3-ap-southeast-1.amazonaws.com/caas-media-storage/upload/photos/LINE_Game_Default_Image/common/prize_result_notwin.png">
            <p class="no-prize-modal__title">
              <span v-if="gameDetails.noPrizeTitle">{{ gameDetails.noPrizeTitle }}</span>
              <span v-else>{{ $t('TRY_AGAIN') }}！</span>
            </p>
            <p class="no-prize-modal__subtitle" v-show="gameDetails.noPrizeSubtitle">{{ gameDetails.noPrizeSubtitle }}</p>
          </section>
          <section
            v-show="gameDetails.enableShare"
            class="no-prize-modal__bottom-area"
          >
            <button
              @click="shareLineMessage"
              class="no-prize-modal__share-to-friends-button"
            >
              <img :src="shareButtonIcon" />
              {{ $t('FRIEND') }}
            </button>
            <div class="no-prize-modal__share-to-friends-text">
              {{ $t('SHARE_WITH_FRIEND') }}！
            </div>
          </section>
        </div>
      </SweetModal>
      <PrizeRecordsModal
        :gameId="gameId"
        :userId="userId"
        :isRecordsModal="isRecordsModal"
        :isGameDraft="isGameDraft"
        @handleRecordsModal="handleRecordsModal"
        @handleSuccessModal="handleSuccessModal"
      />
      <SweetModal
        icon="success"
        ref="successModal"
        :hide-close-button="true"
        overlay-theme="dark"
        class="successModal-icon"
      >
        <div class="alertModal-title">{{ $t('SUCCESS') }}!<br />{{ $t('CHECK_MESSAGE_IN_ROOM') }}</div>
      </SweetModal>
      <SweetModal
        icon="success"
        ref="shareModal"
        :hide-close-button="true"
        overlay-theme="dark"
        class="shareModal-icon"
      >
        <div class="alertModal-title">{{ $t('LINK_COPIED') }}</div>
        <br />{{ $t('SHARE_WITH_FRIEND_2') }}
      </SweetModal>
      <OmnichatFooter />
    </div>
  </div>
</template>

<script>
import liff from '@line/liff'
import gameApi from '../restapi/game'
import router from '../router'
import { SweetModal } from 'sweet-modal-vue'

import ChouqianHooks from './ChouqianHooks.vue'
import CapsuleToy from './CapsuleToy.vue'
import SlotMachine from './SlotMachine.vue'
import ScratchOff from './ScratchOff.vue'
import WinWheel from './WinWheel.vue'
import UserGameData from './UserGameData.vue'
import GameRuleAndPrize from './GameRuleAndPrize.vue'
import PrizeRecordsModal from './PrizeRecordsModal.vue'
import OmnichatFooter from './OmnichatFooter.vue'

import defaultBgImage from '../images/default-bg-image.png'
import chouqianBgImage from '../images/chouqian-bg-image.png'
import capsuleBgImage from '../images/capsule-bg-image.png'
import scratchBgImage from '../images/scratch-bg-image.png'
import winwheelBgImage from '../images/winwheel/winwheel-bg-image.png'
import scratchMainImage from '../images/scratch-main-image.png'
import scratchSecondaryImage from '../images/scratch-secondary-image.png'
import chouqianStartButtonImage from '../images/chouqian-start-button-image.png'
import capsuleStartButtonImage from '../images/capsule-start-button-image.png'
import winwheelPointer from '../images/winwheel/winwheel-pointer.png'
import shareIcon from '../images/icon-share.png'
import shareButtonIcon from '../images/icon-share.svg'
import shareButtonIconPrimary from '../images/icon-share-primary.svg'
import capsuleToyGift from '../images/capsule-toy-gift.png'
import closeIcon from '../images/icon-close.svg'
import sadFaceImage from '../images/face-sad.png'


export default {
  name: 'GamePage',
  props: {
    gameId: String,
    userId: String,
    referrer: String,
    isModuleChannel: Boolean,
    platform: String,
    liffId: String,
  },
  data() {
    return {
      // const
      GAME_TYPE: {
        SlotMachine: 1,
        CapsuleToy: 2,
        ChouqianHooks: 3,
        ScratchOff: 4,
        WinWheel: 5,
      },
      GAME_STATUS: {
        isGameDraft: 0,
        isProcessing: 4,
      },
      // ===== capsule specific
      wonCapsuleclass: 'capsule-toy__won-capsule',
      isNoPrize: false,
      // ===== shared by all games
      displayScratchPlayAgain: false,
      gameDetails: { bgColor: 'yellow', prizes: [] },
      remainingDraws: 0,
      isAnimationOn: false,
      currentRoundPrizeDetails: {},
      displayCurrentPrizeModal: true,
      // ===== shared by all games - UI styles
      bgColor: 'green',
      defaultBgImage,
      bgImage: `url(${defaultBgImage})`,
      mainImage: scratchMainImage,
      secondaryImage: scratchSecondaryImage,
      startButtonImage: chouqianStartButtonImage,
      isGameDraft: false,
      chouqianBgImage,
      capsuleBgImage,
      scratchBgImage,
      winwheelBgImage,
      scratchMainImage,
      scratchSecondaryImage,
      chouqianStartButtonImage,
      capsuleStartButtonImage,
      winwheelPointer,
      shareIcon,
      shareButtonIcon,
      shareButtonIconPrimary,
      capsuleToyGift,
      closeIcon,
      sadFaceImage,
      isWonPrizeModal: false,
      isNoPrizeModal: false,
      isRecordsModal: false,
      isRedeem: false
    }
  },
  async mounted() {
    console.log('in game page');
    console.log(`userId: ${this.userId}`)
    console.log(`gameId: ${this.gameId}`)
    if (this.userId === undefined && this.gameId === undefined) {
      return router.push('error-page')
    }
    await this.handleGameDetails()
  },

  methods: {
    async handleGameDetails() {
      if (this.gameId === '' || this.userId === '') return
      try {
        let res = await gameApi.getGameDetails(
          this.gameId,
          this.userId,
          this.referrer
        )
        let data = res.data.data
        console.log(data)

        if (data.loginRedirectUrl) {
          // Pampers 客製需求
          // Pampers game module need login
          alert('進行遊戲前，請先註冊');
          location.href = data.loginRedirectUrl;
        } else {
          this.gameDetails = data
          this.remainingDraws = data.remainingDraws
          this.handleBackgroundColor(data)
          this.handleBackgroundImage(data)
          this.handleMainAndSecondaryImage(data)
          this.handleStartButtonImage(data)
          if (data.status === this.GAME_STATUS.isGameDraft) {
            this.isGameDraft = true
            document.title = data.name + ` (${this.$t('UNPUBLISHED')})`
          } else {
            document.title = data.name
          }
          return data
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if(error.response.data.code === 11016) return alert(`${this.$t('GAME_NOT_STARTED')}`)
          if(error.response.data.code === 11017) return alert(`${this.$t('GAME_ENDED')}`)
          if(error.response.data.code === 11018) return alert(`${this.$t('GAME_PAUSED')}`)
          if(this.isModuleChannel && error.response.data.code === 17005) return alert(`${this.$t('PARTICIPANTS_LIMIT')}`)
          if(this.isModuleChannel && error.response.data.code === 17006) return alert(`${this.$t('TRIAL_EXPIRED')}`)
          if (this.platform === 'line' && this.isModuleChannel) {
            return alert(`${this.$t('ADD_FRIEND_OR_UNBLOCK')}`)
          }
        } else {
          alert(`${this.$t('UNABLE_START_GAME')}`)
        }
      }
    },
    // only for capsule and chouqian
    // scratch has its own start game function in its own component
    async handleStartGame() {
      if (this.isAnimationOn) return
      this.wonCapsuleclass = 'capsule-toy__won-capsule'
      this.isAnimationOn = true
      try {
        let res = await this.postStartGame()
        if (res.noPrize !== null) {
          if (res.noPrize) {
            this.isNoPrize = true
          } else {
            this.isNoPrize = false
          }
          setTimeout(() => {
            this.isAnimationOn = false
            if (this.gameDetails.type === this.GAME_TYPE.CapsuleToy)
              this.wonCapsuleclass = this.wonCapsuleclass + ' scale-in-top'
            setTimeout(() => {
              if (res.noPrize) {
                this.$refs.NoPrizeModal.open()
                // 未中獎由後端領獎
                // this.handleRedeemPrize(res.prizeRecordId, true, false)
              } else {
                this.$refs.WonPrizeModal.open()
              }
            }, 750)
          }, 2000)
        }
      } catch (err) {
        console.log('handleStartGame: ' + err)
      }
    },
    async postStartGame() {
      try {
        let res = await gameApi.startGame(this.gameId, this.userId)
        let data = res.data.data
        this.currentRoundPrizeDetails = data
        if (this.remainingDraws > 0) this.remainingDraws -= 1
        return data
      } catch (error) {
        this.isAnimationOn = false
        if(this.gameDetails.type === this.GAME_TYPE.ScratchOff) {
          throw new Error('game Start Error')
        }
        if (error.response && error.response.data) {
          let errorResponse = error.response.data
          this.handleErrorMessage(errorResponse)
        } else {
          alert(`${this.$t('UNABLE_START_GAME')}`)
        }
      }
    },
    // 只要是「領取」就要打這支API
    // 因為 API 會發出領取的 message
    async handleRedeemPrize(recordId, isNoPrize) {
      if(this.isRedeem) return
      try {
        this.isRedeem = true
        await gameApi.RedeemPrize(this.gameId, this.userId, recordId)
        if (!isNoPrize) {
          this.$refs.WonPrizeModal.close()
          this.$refs.successModal.open()
        }
      } catch (err) {
        console.log('handleRedeemPrize: ' + err)
      } finally {
        this.isRedeem = false
      }
    },
    handlePlayAgain(isCloseByIcon) {
      switch (this.gameDetails.type) {
        case this.GAME_TYPE.SlotMachine: // 拉霸
          this.handleGameDetails(this.gameId)
          this.displayCurrentPrizeModal = false
          this.$refs.NoPrizeModal.close()
          this.$refs.WonPrizeModal.close()
          // this.isWonPrizeModal = false;
          // this.isNoPrizeModal = false;
          break
        case this.GAME_TYPE.CapsuleToy: // 扭蛋
          this.handleGameDetails(this.gameId)
          this.wonCapsuleclass = 'capsule-toy__won-capsule'
          this.displayCurrentPrizeModal = false
          this.$refs.NoPrizeModal.close()
          this.$refs.WonPrizeModal.close()
          // this.isWonPrizeModal = false;
          // this.isNoPrizeModal = false;
          break
        case this.GAME_TYPE.ChouqianHooks: // 抽籤
          this.handleGameDetails(this.gameId)
          this.displayCurrentPrizeModal = false
          this.$refs.NoPrizeModal.close()
          this.$refs.WonPrizeModal.close()
          // this.isWonPrizeModal = false;
          // this.isNoPrizeModal = false;
          break
        case this.GAME_TYPE.ScratchOff: // 刮刮卡
          // window.location.reload();
          // window.location.href = '/lottery-game/?gid=' + gameId
          this.$refs.NoPrizeModal.close()
          this.$refs.WonPrizeModal.close()
          if (!isCloseByIcon) {
            if (!this.isModuleChannel && this.platform === 'line') {
              window.location.href = `https://liff.line.me/${this.liffId}/?gid=${this.gameId}&platform=line`
            } else if (this.isModuleChannel) {
              window.location.href = `${
                window.location.origin
              }/?gid=${this.gameId}&uid=${
                this.userId
              }&moduleChannel=${true}&platform=line`
            } else if (this.platform === 'facebook'){
              window.location.href = `${window.location.origin}/?gid=${this.gameId}&uid=${this.userId}&platform=${this.platform}`
            } else {
              window.location.href = `${window.location.origin}/?gid=${this.gameId}&uid=${encodeURIComponent(this.userId)}&platform=${this.platform}`
            }
          }
          break
        case this.GAME_TYPE.WinWheel: // 輪盤
          this.handleGameDetails(this.gameId)
          this.displayCurrentPrizeModal = false
          this.$refs.NoPrizeModal.close()
          this.$refs.WonPrizeModal.close()
          // this.isWonPrizeModal = false;
          // this.isNoPrizeModal = false;
          break
      }
    },
    handleErrorMessage(error) {
      switch (error.code) {
        case 11001:
          alert(`${this.$t('ERROR')}`)
          return 11001
        case 11002:
          alert(`${this.$t('GAME_PAUSED')}`)
          return 11002
        case 11003:
          alert(`${this.$t('GAME_ENDED')}`)
          return 11003
        case 11004:
          alert(`${this.$t('GAME_NOT_STARTED')}`)
          return 11004
        case 11005:
          alert(`${this.$t('GAME_NOT_PUBLISHED')}`)
          return 11005
        case 11006:
          alert(`${this.$t('DRAWS_USED_UP')}`)
          return 11006
        // 處理 Module Channel 沒加入好友 or 封鎖的狀況
        case 10003:
          alert(`${this.$t('UNLOCK_TO_START_GAME')}`)
          return 10003
      }
    },
    handleStartButtonImage(data) {
      switch (data.type) {
        case this.GAME_TYPE.SlotMachine: // 拉霸
          break
        case this.GAME_TYPE.CapsuleToy: // 扭蛋
          data.startButtonImage !== ''
            ? (this.startButtonImage = data.startButtonImage)
            : (this.startButtonImage = capsuleStartButtonImage)
          break
        case this.GAME_TYPE.ChouqianHooks: // 抽籤
          data.startButtonImage !== ''
            ? (this.startButtonImage = data.startButtonImage)
            : (this.startButtonImage = chouqianStartButtonImage)
          break
        case this.GAME_TYPE.ScratchOff: // 刮刮卡
          break
        case this.GAME_TYPE.WinWheel: // 輪盤
          break
      }
    },
    handleMainAndSecondaryImage(data) {
      switch (data.type) {
        case this.GAME_TYPE.SlotMachine: // 拉霸
          break
        case this.GAME_TYPE.CapsuleToy: // 扭蛋
          break
        case this.GAME_TYPE.ChouqianHooks: // 抽籤
          break
        case this.GAME_TYPE.ScratchOff: // 刮刮卡
          data.secondaryImage !== ''
            ? (this.secondaryImage = data.secondaryImage)
            : (this.secondaryImage = scratchSecondaryImage)
          data.mainImage !== ''
            ? (this.mainImage = data.mainImage)
            : (this.mainImage = scratchMainImage)
          break
        case this.GAME_TYPE.WinWheel: // 輪盤
          data.secondaryImage !== ''
            ? (this.secondaryImage = data.secondaryImage)
            : (this.secondaryImage = winwheelPointer)
          break
      }
    },
    handleBackgroundColor(data) {
      if (data.bgColor !== '') {
        return (this.bgColor = data.bgColor)
      } else {
        switch (data.type) {
          case this.GAME_TYPE.SlotMachine: // 拉霸
            return (this.bgColor = '#FFC670')
          case this.GAME_TYPE.CapsuleToy: // 扭蛋
            return (this.bgColor = '#9C84FF')
          case this.GAME_TYPE.ChouqianHooks: // 抽籤
            return (this.bgColor = '#FFD98F')
          case this.GAME_TYPE.ScratchOff: // 刮刮卡
            return (this.bgColor = '#7277FF')
          case this.GAME_TYPE.WinWheel: // 輪盤
            return (this.bgColor = '#C184FF')
        }
      }
    },
    handleBackgroundImage(data) {
      if (data.bgImage !== '') {
        return (this.bgImage = `url(${data.bgImage})`)
      } else {
        switch (data.type) {
          case this.GAME_TYPE.SlotMachine: // 拉霸
            break
          case this.GAME_TYPE.CapsuleToy: // 扭蛋
            return (this.bgImage = `url( ${this.capsuleBgImage} )`)
          case this.GAME_TYPE.ChouqianHooks: // 抽籤
            return (this.bgImage = `url( ${this.chouqianBgImage} )`)
          case this.GAME_TYPE.ScratchOff: // 刮刮卡
            return (this.bgImage = `url( ${this.scratchBgImage} )`)
          case this.GAME_TYPE.WinWheel: // 輪盤
            return (this.bgImage = `url( ${this.winwheelBgImage} )`)
        }
      }
    },
    handleRecordsModal(isOn) {
      this.isRecordsModal = isOn
    },
    handleModalStatus(isPrize) {
      if (isPrize) {
        this.$refs.WonPrizeModal.open()
        // this.isWonPrizeModal = true;
      } else {
        this.$refs.NoPrizeModal.open()
        // this.isNoPrizeModal = true;
      }
    },
    async shareLineMessage() {
      try {
        if (!this.isModuleChannel && this.platform === 'line') {
          // 非 module channel，使用 shareTargetPicker 來做分享給好友
          if (liff.isApiAvailable('shareTargetPicker')) {
            const result = await liff.shareTargetPicker([
              {
                type: 'text',
                text: this.gameDetails.shareText,
              },
            ])
            if (result) alert(`${this.$t('SHARE_SUCCESSFULLY')}`)
          } else {
            alert(`${this.$t('LINE_VERSION_NOT_SUPPORT')}`)
          }
        } else {
          let dummyInput = document.createElement('input')
          document.body.appendChild(dummyInput)
          document
            .querySelector('.sweet-content-content')
            .setAttribute('style', 'height: auto')
          console.log(document.querySelector('.sweet-content-content'))
          dummyInput.value = this.gameDetails.shareText // 要確認一下 shareUrl 是否直接從 gameDetail 拿
          dummyInput.select()
          dummyInput.setSelectionRange(0, 99999)
          document.execCommand('copy')
          document.body.removeChild(dummyInput)
          this.$refs.NoPrizeModal.close()
          this.$refs.WonPrizeModal.close()
          this.$refs.shareModal.open()
          // let sweetModalHeight = document.querySelector('.sweet-content-content')
          // sweetModalHeight.style.height = 'auto'
          // console.log(sweetModalHeight)
          // alert(
          //   "已成功複製分享連結！\n您可以向好友分享這條連結，若好友透過這條連結進入遊戲，則您會增加一次遊玩機會！"
          // );
        }
        // liff.closeWindow();
      } catch (err) {
        alert('shareLineMessage: ' + err)
      }
    },
    handleSuccessModal() {
      this.$refs.successModal.open()
    },
    handleScratchPlayAgain() {
      this.displayScratchPlayAgain = true
    },
  },
  components: {
    SweetModal,
    ChouqianHooks,
    UserGameData,
    GameRuleAndPrize,
    PrizeRecordsModal,
    OmnichatFooter,
    CapsuleToy,
    SlotMachine,
    WinWheel,
    ScratchOff,
  },
}
</script>

<style>
.games__main-container {
  min-width: 300px;
}

.main-background-image {
  min-width: 300px;
  max-width: 400px;
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100%;
  background-position: top;
  background-size: 400px auto;
  background-repeat: no-repeat;
}
.main-background-image img {
  width: 100%;
}

.won-prize-modal__prize-image {
  border-radius: 4px;
  height: 100px;
}

.won-prize-modal__prize-name {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #4CD964;
}

.won-prize-modal__button-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.won-prize-modal__button-top-row {
  display: flex;
}
.no-prize-modal__share-to-friends-button,
.won-prize-modal__redeem-prize-button,
.won-prize-modal__play-again-button,
.won-prize-modal__share-to-friends-button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.won-prize-modal__redeem-prize-button,
.won-prize-modal__play-again-button {
  /* width: 120px; */
  width: 100%;
  background-color: #408fff;
  color: #fff;
}
.won-prize-modal__play-again-button {
  margin-right: 10px;
}

.won-prize-modal__share-to-friends-button,
.no-prize-modal__share-to-friends-button {
  width: 100%;
  background-color: #408FFF;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 8px 0;
}

.won-prize-modal__share-to-friends-button {
  color: #408FFF;
  background-color: transparent;
}

.no-prize-modal__share-to-friends-button img,
.won-prize-modal__share-to-friends-button img {
  width: 20px;
  margin-right: 5px;
}
.won-prize-modal__share-to-friends-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
}

.won-prize-modal__wrapper,
.no-prize-modal__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.won-prize-modal__bottom-area,
.no-prize-modal__bottom-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.won-prize-modal__top-area,
.no-prize-modal__top-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  margin: 0 0 24px 0;
}
.won-prize-modal__top-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
}

.won-prize-modal__image,
.no-prize-modal__image {
  width: 120px;
  margin: 0 0 24px 0;
}
.won-prize-modal__title,
.no-prize-modal__title {
  word-break: break-word;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}

.won-prize-modal__subtitle {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  justify-content: center;
  margin-top: 8px;
}

.won-prize-modal__subtitle,
.no-prize-modal__subtitle {
  word-break: break-all;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #000000;
}

.no-prize-modal__share-to-friends-text {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #AFAFAF;
}

.prize-modal__draft-warning {
  font-weight: 500;
  font-size: 14px;
  color: red;
  /* margin-bottom: 16px; */
  text-align: center;
}
@media screen and (min-width: 400px) {
  #app {
    width: 400px;
    margin: 0 auto;
  }
  .main-background-image {
    width: 400px;
  }
}
</style>